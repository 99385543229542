import React, { Suspense } from "react";
import { Modal, Spin } from "antd";
import { FloorPlan } from "../FloorPlan";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { RoomInfo } from "./RoomInfo";
import { AnimatePresence, motion } from "framer-motion";
import useMousePosition from "../../hooks/useMousePosition";
import { getFloorLevel } from '../../helpers/getFloorLevel';
import {useAppDispatch, useAppSelector} from "../../store/store.utils";
import {$api_actions} from "../../reducers/apiSlice";
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const PlanViewer = ({
  plan,
  setPlan,
  planViewRef,
  roomId,
  setRoomId,
}) => {
  const [hoveredRoom, setHoveredRoom] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [svgLoaded, setSvgLoaded] = React.useState(false);
  const dispatch = useAppDispatch()
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const isScreenSmallerThan1200px = useMediaQuery('(max-width: 1200px)');
  const isScreenLargerThan1536px = useMediaQuery('(min-width: 1536px)');

  const floors = useAppSelector(state => state.api.floors)
  const toStringFloor = getFloorLevel(plan)


  const isSmallerThan480px = useMediaQuery('(max-width: 480px)');


  const position = useMousePosition();
  const maxScale  = isSmallerThan480px ? 1.3 : isScreenSmallerThan1200px ? 3 :isScreenLargerThan1536px ? 5 :3 
  const scaleMultiplier = isSmallerThan480px ? 1.6: 1.5
  const transformComponentRef = React.useRef(null)


  React.useEffect(() => {
    if (svgLoaded && transformComponentRef.current) {
      transformComponentRef.current.centerView()
      console.log(transformComponentRef.current)
    }
  }, [transformComponentRef, svgLoaded])

  return (
    <>
    <div className="PlanViewer">
        <div style={{ width: "100%", height: "11%" }}></div>
        <div className="PlanViewer_content">
          <div className="PlanViewer_sider">
            {/* <button
              className='PlanViewer_floor-changeButton'
              onClick={() => {
                setPlan(prev => prev === 0 ? prev : prev - 1)
              }}
            >
              <UpOutlined />
            </button> */}
            <div className="PlanViewer_floor">
              <div className="PlanViewer_floor-caption">Floor {plan}</div>
              <div className="PlanViewer_floor-available">
                {floors[plan > 9 ? plan : '0' + plan].units.filter(unit => unit.isAvailable).length} Units Available
              </div>
            </div>
            {/* <button
              className='PlanViewer_floor-changeButton'
              onClick={() => {
                setPlan(prev => prev === 34 ? prev : prev + 1)
              }}
            >
              <DownOutlined />
            </button> */}
          </div>
          
          <div className="PlanViewer_plan">
            <TransformWrapper
                ref={transformComponentRef}
              centerOnInit
              defaultScale={1.4}
              initialScale={maxScale}
              maxScale={maxScale *scaleMultiplier}
              onInit={(ref) => ref.zoomToElement(planViewRef.current, undefined, 100)}
            >
              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  margin: 0,
                  padding: 0,
                }}
              >
                <div
                  ref={planViewRef}
                  className="PlanViewer_floorPlanContainer"
                  style={{ transition: "all 0.3s"}}
                >
                  <FloorPlan
                      setSvgLoaded={setSvgLoaded}
                      plan={plan}
                      floorLevel={toStringFloor}
                      onMouseOver={(event) => {
                        const dataSet = event.target.getAttribute('data-set');
                        if (dataSet === 'apartment') {
                          const hr = event.target.id.match(/\d+/)[0]

                          let correctedPlan = plan <= 9 ? '0' + plan : plan
                          let slId = `SL-${correctedPlan}${hr}`
                          let hoveredRoomData = floors[correctedPlan].units.find(unit => unit.id === slId)


                          if (hoveredRoomData.isAvailable) {
                            setHoveredRoom(hr);
                            event.target.setAttribute("fill", "#F0B043");
                            event.target.setAttribute("fill-opacity", "0.9");
                            return
                          }

                          setHoveredRoom(null)
                        } else {
                          setHoveredRoom(null)
                        }
                      }}
                      onClick={(event) => {
                        const dataSet = event.target.getAttribute('data-set');
                        if (dataSet === 'apartment') {
                          let correctedPlan = plan <= 9 ? '0' + plan : plan
                          let slId = `SL-${correctedPlan}${hoveredRoom}`
                          let hoveredRoomData = floors[correctedPlan].units.find(unit => unit.id === slId)

                          if (hoveredRoomData.isAvailable) {
                            dispatch($api_actions.setState({activeUnit: hoveredRoomData}))
                            setRoomId((event.target.id.match(/\d+/)[0]));
                          }

                        }
                      }}
                      setPageLoaded={setPageLoaded}
                  />
                </div>
              </TransformComponent>
            </TransformWrapper>
            <RoomInfo
              plan={plan}
              onView={() => setRoomId(hoveredRoom?.split("room").pop())}
              hoveredRoom={hoveredRoom}
              position={window.innerWidth > 425 ? position : null}
            />
          </div>
        </div>
      </div>

    {pageLoaded ? null:    <Spin className="houseLoading" size="large" />}
      
{/*      {roomId !== null && !loaded && (
        <Spin size="large" className="houseLoading" />
      )}*/}
    </>
  );
};
