import {configureStore} from '@reduxjs/toolkit'
import apiSlice from "../reducers/apiSlice";
import filterSlice from "../reducers/filterSlice";

export const store = configureStore({
    reducer: {
        api: apiSlice,
        filter: filterSlice,
    },

})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch