export const getFloorLevel = (floor) => {
  if (floor >= 4 && floor <= 9) {
    return '04-09';
  }
  if (floor >= 10 && floor <= 11) {
    return '10-11';
  }
  if (floor >= 14 && floor <= 22) {
    return '14-22';
  }
  if (floor >= 28 && floor <= 32) {
    return '28-32';
  }
  if (floor < 10) {
    return '0' + floor;
  }
  return String(floor);
}