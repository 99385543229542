import * as React from "react";

export const MenuIcon = ({ className = "", ...props }) => {
  return (
    <svg
      {...props}
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.8947 10.1055H0.947369C0.423158 10.1055 0 10.5286 0 11.0528C0 11.577 0.423158 12.0002 0.947369 12.0002H19.8947C20.4189 12.0002 20.8421 11.577 20.8421 11.0528C20.8421 10.5286 20.4189 10.1055 19.8947 10.1055Z"
        fill="#FDFBF6"
      />
      <path
        d="M19.8947 5.05273H0.947369C0.423158 5.05273 0 5.47589 0 6.0001C0 6.52431 0.423158 6.94747 0.947369 6.94747H19.8947C20.4189 6.94747 20.8421 6.52431 20.8421 6.0001C20.8421 5.47589 20.4189 5.05273 19.8947 5.05273Z"
        fill="#FDFBF6"
      />
      <path
        d="M0.947369 1.89474H19.8947C20.4189 1.89474 20.8421 1.47158 20.8421 0.947369C20.8421 0.423158 20.4189 0 19.8947 0H0.947369C0.423158 0 0 0.423158 0 0.947369C0 1.47158 0.423158 1.89474 0.947369 1.89474Z"
        fill="#FDFBF6"
      />
    </svg>
  );
};
