import React, { useState,useEffect } from 'react'
import {Checkbox, DatePicker, Select,Slider } from 'antd';
import {UpIcon} from './icons/UpIcon';
import {useAppDispatch, useAppSelector} from "../store/store.utils";
import {setBathroomsFilter, setAvailabilityFilter, setPriceFilter, setTypeFilter} from "../reducers/filterSlice";
import { getOptions } from '../helpers/getOptions';
import { CloseOutlined } from '@ant-design/icons';

export const DropDownBlock = ({ setSearchOpen}) => {
    const floors = useAppSelector(state => state.api.floors)
    const dispatch = useAppDispatch()

    const { filteredBedroomOptions, filteredBathroomsOptions,filteredPriceOptions } = getOptions(floors)

    const filteredBedroomsValue = filteredBedroomOptions.map(el => el.value)
    const filteredBathroomsValue = filteredBathroomsOptions.map(el => el.value)

    const [price,setPrice] = useState(filteredPriceOptions)
    const [bedroomsValue,setBedroomsValue] = useState(filteredBedroomsValue)
    const [bathroomsValue,setBathroomsValue] = useState(filteredBathroomsValue)

    useEffect(()=> {
      setBedroomsValue(filteredBedroomsValue)
      setBathroomsValue(filteredBathroomsValue)
    },[floors])

    const handleChangePriceFilter = (v) => {
      const [min, max] = v
      setPrice(v)
      dispatch(setPriceFilter({min, max}))
    }

    const handleChangeBathroomsFilter = (e) => {
      const numberArr = e.map(el => parseInt(el))
      setBathroomsValue(e)
      dispatch(setBathroomsFilter(numberArr))
    }

    const handleChangeTypeFilter = (e) => {
      const numberArr = e.map(el => parseInt(el))
      setBedroomsValue(e)
      dispatch(setTypeFilter(numberArr))
    }

    const handleChangeDateFilter = (_, date) => {
      dispatch(setAvailabilityFilter(new Date(date)))
    }
    
    const hadnleResetFilters = () => {
      const [min, max] = filteredPriceOptions
      const numberBathromsValue = filteredBathroomsValue.map(el => parseInt(el))
      const numberBedromsValue = filteredBedroomsValue.map(el => parseInt(el))

      setBedroomsValue(filteredBedroomsValue)
      setBathroomsValue(filteredBathroomsValue)
      setPrice(filteredPriceOptions)

      dispatch(setTypeFilter(numberBedromsValue))
      dispatch(setBathroomsFilter(numberBathromsValue))
      dispatch(setPriceFilter({min, max}))
    }

    const dropdownRender = (menu,options,handleChange,value) =>(
      <div style={{  padding: '8px' }}>
        <Checkbox.Group
          options={options}
          onChange={handleChange}
          className='footer-checkbox'
          value={value}
        />
      </div>
    )
    return (
        <>
          <div className="cross-icon-footer"  >
            <CloseOutlined onClick={(e)=> setSearchOpen(null)} />
          </div>
          <Select
            defaultValue="Bedrooms"
            style={{width: '100%', marginBottom: '10px'}}
            popupClassName="footer_search-dropdown"
            // value={filterOptions.amenities}
            suffixIcon={<UpIcon style={{rotate: '180deg', marginRight: '10px', scale: '0.65'}}/>}
            dropdownRender={(menu)=> dropdownRender(menu,filteredBedroomOptions ,handleChangeTypeFilter,bedroomsValue)}
          />
          <Select
            defaultValue="Bathrooms"
            style={{width: '100%', marginBottom: '10px'}}
            popupClassName="footer_search-dropdown"
            suffixIcon={<UpIcon style={{rotate: '180deg', marginRight: '10px', scale: '0.65'}}/>}
            // value={filterOptions.bedRooms}
            dropdownRender={(menu)=> dropdownRender(menu,filteredBathroomsOptions,handleChangeBathroomsFilter,bathroomsValue)}
          />
          
          <DatePicker
            style={{width: '100%', marginBottom: '10px'}}
            onChange={handleChangeDateFilter}
            placeholder="Move in Date" 
            suffixIcon={<UpIcon style={{rotate: '180deg', marginRight: '10px', scale: '0.65'}}/>}
            className="footer_search-dropdown"
            inputReadOnly={true}
            allowClear={false}
          />
          <h4 style={{fontSize:'13px',margin:'5px',marginBlock:0}}>Price</h4>
          <Slider
            range
            className='price-slider'
            min={filteredPriceOptions[0]}
            max={filteredPriceOptions[1]}
            value={price}
            onChange={handleChangePriceFilter}
          />
          <button className="footer_search-button" onClick={hadnleResetFilters}>Reset Filter</button>
        </>
    )
};
