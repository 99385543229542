import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './api/apiProperties.requests'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Map3d from "./Map3d";
import { ErrorRoute } from './ErrorRoute';
import { House } from './House';
import { Provider } from "react-redux";
import { store } from "./store/store";
import Contact from './components/Contact/Contact';
import ProjectLoader from "./ProjectLoader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Map3d />,
    errorElement: <ErrorRoute />,
  },
  {
    path: "/house",
    element: <House />,
  },
  {
    path: "/contact",
    element: <Contact />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
    <ProjectLoader />
  </Provider>
);
