import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface IFilterState {
    type: number[]
    availability: Date | string
    bathrooms: number[]
    price: {
        min: number
        max: number
    }
}

const initialState: IFilterState = {
    type: [],
    availability: '',
    bathrooms: [],
    price: {
        min: 0,
        max: 0,
    },
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setTypeFilter: (state, action: PayloadAction<number[]>) => {
            state.type = action.payload
        },
        setBathroomsFilter: (state, action: PayloadAction<number[]>) => {
            state.bathrooms = action.payload
        },
        setPriceFilter: (state, action: PayloadAction<{ min: number; max: number }>) => {
            state.price = action.payload
        },
        setAvailabilityFilter: (state, action: PayloadAction<Date>) => {
            state.availability = action.payload
        }
    }
})

export const {
    setTypeFilter,
    setBathroomsFilter,
    setPriceFilter,
    setAvailabilityFilter,
} = filterSlice.actions

export default filterSlice.reducer