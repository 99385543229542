import React, { useState } from 'react';
import './Contact.scss';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useDispatch } from 'react-redux';
import { $api_actions } from '../../reducers/apiSlice';
import { GENERIC_PROPERTY_ID } from '../../constans/constans';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const isSmallerThan480px = useMediaQuery('(max-width: 480px)');
  const dispatch = useDispatch()

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1)
  };

  const handleSubmit =async (event) => {
    event.preventDefault();
    
    const contactInfo = {
      ...formData,
     genericPropertyId:GENERIC_PROPERTY_ID
    }
    
    await dispatch($api_actions.sendContactUs(contactInfo))
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: ''
    })
  };

   const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  
  };
  return (
    <div className="contact-page">
      <div className="cross-icon" onClick={handleGoBack}>
        <img src="/cross.svg" alt="Cross Icon" />
      </div>
      <h1>Contact</h1>
      <form className="contact-form" >
        <div className="row">
          <div className="col">
            <input type="text" name="firstName" placeholder="First Name*" value={formData.firstName}  onChange={handleChange}required />
          </div>
          <div className="col">
            <input type="text" name="lastName" placeholder="Last Name*"value={formData.lastName}  onChange={handleChange} required />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input type="text" name="email" placeholder="Email Address*" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="col">
            <input type="text" name="phoneNumber" placeholder="Phone number*"  value={formData.phoneNumber} onChange={handleChange} required></input>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input type="text" name="message" placeholder="Message" rows="4" value={formData.message} onChange={handleChange}></input>
          </div>
        </div>
      </form>
      <p className="title">
        This site is protected by reCAPTCHA.{isSmallerThan480px && <br/>} Google <u> Privacy Policy</u> and <u>Terms of Service</u> apply.
      </p>
      <div className="col submit ">
        <input onClick={handleSubmit} type="submit" value="Submit"  form="contact-form" />
      </div>
      <div className="additional-info">
        <div  className='footer-box'>
          <p className='top-footer'>Location</p>
          <p className='bottom-footer'>Flow Las Olas <br/> 301 SW 1st Ave Fort Lauderdale <br/>FL 33301 </p>
        </div>
        <div className='footer-box'>
          <p className='top-footer'>Operation Hours</p>
          <p className='bottom-footer'>Monday - Friday: 10 AM - 6 PM <br/> Saturday: 10 AM to 5 PM </p>
        </div>
        <div className='footer-box'>
          <p className='top-footer'>Phone</p>
          <p className='bottom-footer'>(954) 524-5656</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
