export const applyFilters = (array, typeFilter, bathroomsFilter, availabilityFilter, priceFilter) => {
    let newArr = [...array]
    // TYPE FILTER
    if (typeFilter.length) {
        newArr = newArr.filter((i) => typeFilter.includes(i.floorplan.bedCount))
    }

    // BATHROOMS FILTER
    if (bathroomsFilter.length) {
        newArr = newArr.filter((i) => bathroomsFilter.includes(i.floorplan.bathCount))
    }


    // PRICE FILTER
    if (priceFilter.min > 0 || priceFilter.max > 0) {
        if (priceFilter.min > 0 && priceFilter.max === 0) {
            newArr = newArr.filter(i => parseFloat(i.marketRent) > priceFilter.min)
        } else if (priceFilter.max > 0 && priceFilter.min === 0) {
            newArr = newArr.filter(i => parseFloat(i.marketRent) < priceFilter.max)
        } else if (priceFilter.max > 0 && priceFilter.min > 0) {
            newArr = newArr.filter(i => parseFloat(i.marketRent) > priceFilter.min && parseFloat(i.marketRent) < priceFilter.max)
        }
    }

    // DATE FILTER
    if (availabilityFilter !== '') {
        newArr = newArr.filter((i) => {
            if (i.madeReadyDate === null) return true
            if (new Date(i.madeReadyDate) < availabilityFilter) return true
            return false
        })
    }

    return newArr
}