import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import { Layer, Map } from "react-map-gl";
import { AmbientLight, _SunLight as SunLight, DirectionalLight, PointLight, LightingEffect } from "@deck.gl/core";
import DeckGL from "@deck.gl/react";
import { PolygonLayer } from "@deck.gl/layers";
import { TripsLayer } from "@deck.gl/geo-layers";
import { usePolygonLayer } from "./hooks/usePolygonLayer.js";
import { DeckGLWrapper } from "./context/DeckContext.jsx";
import 'mapbox-gl/dist/mapbox-gl.css'

import mapboxgl, { ScrollZoomHandler } from 'mapbox-gl';
import { usePins } from "./hooks/usePins.js";
import { TOKEN } from "./constants.js";
import { useDirection } from "./hooks/useDirection.js";
import { useMainBuilding } from "./hooks/useMainBuilding.js";
import './App.css'
import useClickOutside from "./hooks/useClickOutside.js";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// Source data CSV
const DATA_URL = {
  BUILDINGS:
    "https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/buildings.json",
  TRIPS:
    "https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/trips-v7.json", // eslint-disable-line
};

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1,
});


const directionalLight = new DirectionalLight({
  color: [255, 255, 255],
  intensity: 1.0,
  direction: [-3, -9, -1]
});

const sun = new SunLight({
  timestamp: Date.now(), 
  color: [255, 0, 0],
  intensity: 1

});
const lightingEffect = new LightingEffect({
  ambientLight,
  directionalLight,
  // sun,

});


const material = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70],
};

const DEFAULT_THEME = {
  buildingColor: [255, 255, 255],
  trailColor0: [253, 128, 93],
  trailColor1: [23, 184, 190],
  material,
  effects: [lightingEffect],
};

const INITIAL_VIEW_STATE = {};

const MAP_STYLE =
  "https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json";

const landCover = [
  [
    [-74.0, 40.7],
    [-74.02, 40.7],
    [-74.02, 40.72],
    [-74.0, 40.72],
  ],
];

const a = [26.11944269790867, -80.144855417212]


function Map3d({
  buildings = DATA_URL.BUILDINGS,
  trips = DATA_URL.TRIPS,
  trailLength = 180,
  initialViewState = INITIAL_VIEW_STATE,
  mapStyle = MAP_STYLE,
  theme = DEFAULT_THEME,
  loopLength = 1800, // unit corresponds to the timestamp in source data
  animationSpeed = 1,
}) {
  // const l = usePolygonLayer(buildings, theme);
  // const [showPopup, setShowPopup] = React.useState(false)
  const [mapbox, setMapbox] = React.useState(false)
  const [activeHotspot, setActiveHotspot] = React.useState(null)
  const [explore, setExplore] = React.useState(null);
  const navigate = useNavigate();


  const popupRef = React.useRef();
  
  useClickOutside(popupRef, () => {
    if (explore) {
      setExplore(false)
      
    } else {

      setExplore(false)
      setActiveHotspot(false)
    }
  })

  const [hovered, setHovered] = useState(null)
  
  const getToolTip = ({ object, ...rest }) => {
    
    if (object) {
      setHovered(object.coordinates)
      
      return ({
        html: `
          <div class='tooltip'>
            <div class='tooltip-inner'>
              <div> ${object.name} </div> 
            </div>
          </div>
        `,
        style: {
          backgroundColor: 'transparent',
          fontSize: '0.8em'
        }
      })
    }
  };

  const handleMainBuildingClick = (a, b) => {
    navigate('./house');
  }

  const onPinClick = (a, b) => {
  
    if (a.object.main) {
      handleMainBuildingClick()
    } else {
      setActiveHotspot(p => {
        if (p) {
          return null;
        } else {
          return { a, b }
        }
      })
    }
  }


  const pl = useDirection(a, hovered)
  const mb = useMainBuilding(handleMainBuildingClick)
  const pins = usePins(onPinClick)

  const layers = [
    pl,
    pins,
    mb
  ];

  const exploreStyle = explore ? {
    width: window.innerWidth + 'px',
    height: window.innerHeight + 'px',
    left: 0,
    top: 0,
  } : null

  const renderHotspot = () => {
    if (!activeHotspot) return null
    
    return (
      <div className="background">
        <div style={{
          left: activeHotspot.b.center.x - 500 / 2 + 'px',
          top: activeHotspot.b.center.y - 350 / 2 + 'px',
          zIndex: 21,
          opacity: 1,
          transition: 'all 0.5s',

          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          width: 500,
          height: 350 / 2,
          backgroundImage: `url(${activeHotspot.a.object.image})`,
          ...exploreStyle,
        }} className='pin-hotspot-image-fake'>

        </div>
        <div style={{
          zIndex: 20,
          position: 'absolute',
          left: activeHotspot.b.center.x + 'px',
          top: activeHotspot.b.center.y + 'px',
        }} className='pin-hotspot' ref={popupRef}>
          <div className='pint-hotspot-wrapper'>
            <div className={'pin-hotspot-image'}
              style={{
                backgroundImage: `url(${activeHotspot.a.object.image})`,
              }}
            >
            </div>
            <div className='pin-hotspot-text'>
              <div className="pin-hotspot-text-title" dangerouslySetInnerHTML={{__html: activeHotspot.a.object.name}}>
              </div>
              <div className="pin-hotspot-text-subTitle">
                {activeHotspot.a.object.address}
              </div>
              <div className="pin-hotspot-text-text">
                {activeHotspot.a.object.description || 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officiis perferendis mollitia libero voluptatum dolores eos enim, fugiat eaque eius et!'}
              </div>
              <div className="pin-hotspot-text-button-wrapper">
                <div className="pin-hotspot-text-button" onClick={() => setExplore(true)}>
                  Explore
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <>
      <DeckGLWrapper
        layers={layers}
        effects={theme.effects}
        initialViewState={initialViewState}
        controller={true}
        mapboxLoaded={mapbox}
        getTooltip={getToolTip}
      >
        <Map
          mapboxAccessToken={TOKEN}
          antialias={true}
          
          reuseMaps
          //map style
          mapStyle={'mapbox://styles/ruslan-holei/clcqc3dpk007414leill5woyc'}
          onLoad={(e) => setMapbox(e.target)}
        >
          <Layer
            interactive={true}
            id={"add-3d-buildings"}
            source={"composite"}
            source-layer={"building"}
            filter={["==", "extrude", "true"]}
            type={"fill-extrusion"}
            minzoom={15}
            paint={{
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["*", ["get", "height"], 1.5]
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"]
              ],
              "fill-extrusion-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                16,
                1
              ],
              //buildings color
              "fill-extrusion-color": "#f4ead4",
            }}
          ></Layer>
        </Map>
      </DeckGLWrapper>
      {renderHotspot()}
    </>
  );
}

export default Map3d;
