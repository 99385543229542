import { ScenegraphLayer } from "@deck.gl/mesh-layers";

const d = [
  {
    name: "FLOW BUILDING",
    code: "LF",
    address: "300 SW 1st Ave",
    entries: "1",
    exits: "1",
    coordinates: [26.11944269790867, -80.144855417212],
    //COLOR MAIN  
    color: [255, 194, 87, 255],
  },
];

const useMainBuilding = (onClick) => {
  const layer = new ScenegraphLayer({
    onClick,
    id: "main-building-layer",
    data: d,
    pickable: true,
    scenegraph: "./Building_001.glb",
    getPosition: (d) => [d.coordinates[1], d.coordinates[0]],
    getOrientation: (d) => [0, 1, 90],
    getTranslation: () => [-6, 20, 0],
    sizeScale: 88,
    opacity: 1,
    _lighting: "pbr",
    getColor: (d) => d.color || [255, 255, 255, 255],
  });

  return layer;
};

export { useMainBuilding };
