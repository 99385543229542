import React from "react";
import { Row, Col, Select, DatePicker } from "antd";
import { Logo } from "./icons/Logo";
import { MenuIcon } from "./icons/MenuIcon";
import styles from "./Header.module.css";

export const Header = () => (
  <div className={styles["header"]}>
    <Row className={styles["header-row"]}>
      <MenuIcon className={styles["menu-icon"]} />
      <Col span={3} offset={1} className={styles["header-first-col"]}>
        <Logo />
        <p className={styles["header_text"]}>Las Olas</p>
      </Col>
      <p className={styles["sign_in"]}>SIGN IN</p>
      <Col span={8} offset={4} className={styles["header-col"]}>
        <p
          className={`${styles["header_text"]} ${styles["header_text_small"]}`}
        >
          Overview
        </p>
        <p
          className={`${styles["header_text"]} ${styles["header_text_small"]}`}
        >
          Amenities
        </p>
        <p
          className={`${styles["header_text"]} ${styles["header_text_small"]}`}
        >
          Neighborhood
        </p>
        <p
          className={`${styles["header_text"]} ${styles["header_text_small"]}`}
        >
          Availability
        </p>
        <p
          className={`${styles["header_text"]} ${styles["header_text_small"]}`}
        >
          Gallery
        </p>
      </Col>
    </Row>
  </div>
);
