import React from 'react'

export const ErrorRoute = () => {
  return (
    <div className='errotPage'>
      404 Not Found
    </div>
  )
}

