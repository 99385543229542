/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {REACT_APP_API_CLIENT_ID, REACT_APP_API_CLIENT_SECRET, REACT_APP_BASE_URL} from "../constans/constans";

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AdminService {
  /**
   *
   */
  static property(
    params: {
      /** requestBody */
      body?: PropertyAdminDetailsDTO;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/admin/property';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static property1(options: IRequestOptions = {}): Promise<PropertyAdminDetailsDTO[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/admin/property';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static property2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PropertyAdminDetailsDTO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/admin/property/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static property3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: PropertyAdminDetailsDTO;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/admin/property/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AmenitiesService {
  /**
   *
   */
  static amenities(
    params: {
      /**  */
      genericPropertyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AmenityModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/amenities/{genericPropertyId}';
      url = url.replace('{genericPropertyId}', params['genericPropertyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FloorplansService {
  /**
   *
   */
  static floorplans(
    params: {
      /**  */
      genericPropertyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FloorplanModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/floorplans/{genericPropertyId}';
      url = url.replace('{genericPropertyId}', params['genericPropertyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */
      axios(configs, resolve, reject);
    });
  }
}

export class PropertyService {
  /**
   *
   */
  static property(
    params: {
      /**  */
      genericPropertyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PropertyDetailsModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/property/{genericPropertyId}';
      url = url.replace('{genericPropertyId}', params['genericPropertyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static officeHours(
    params: {
      /**  */
      genericPropertyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PropertyOfficeHoursModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/property/{genericPropertyId}/office-hours';
      url = url.replace('{genericPropertyId}', params['genericPropertyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProspectService {
  /**
   *
   */
  static contactUs(
    params: {
      /**  */
      genericPropertyId: string;
      /**  */
      firstName?: string;
      /**  */
      lastName?: string;
      /**  */
      email?: string;
      /**  */
      phone?: string;
      /**  */
      enableTextMessages?: boolean;
      /**  */
      message?: string;
      /**  */
      source?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/prospect/contact-us/{genericPropertyId}';
      url = url.replace('{genericPropertyId}', params['genericPropertyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        FirstName: params['firstName'],
        LastName: params['lastName'],
        Email: params['email'],
        Phone: params['phone'],
        EnableTextMessages: params['enableTextMessages'],
        Message: params['message'],
        Source: params['source']
      };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SourceService {
  /**
   *
   */
  static referral(
    params: {
      /**  */
      genericPropertyId: string;
      /**  */
      referrerUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SourceReferralModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/source/{genericPropertyId}/referral';
      url = url.replace('{genericPropertyId}', params['genericPropertyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { referrerUrl: params['referrerUrl'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class UnitsService {
  /**
   *
   */
  static units(
    params: {
      /**  */
      genericPropertyId: string;
      /**  */
      floorplanId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UnitModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/units/{genericPropertyId}';
      url = url.replace('{genericPropertyId}', params['genericPropertyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { floorplanId: params['floorplanId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WebhooksService {
  /**
   *
   */
  static parcelpending(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/webhooks/parcelpending';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface AddressInfoModel {
  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  postalCode?: string;

  /**  */
  country?: string;
}

export interface AmenityModel {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  images?: string[];

  /**  */
  level?: AmenityLevelEnum;

  /**  */
  order?: number;
}

export interface FloorplanModel {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  bedCount?: number;

  /**  */
  bathCount?: number;

  /**  */
  images?: string[];

  /**  */
  minSquareFeet?: number;

  /**  */
  maxSquareFeet?: number;

  /**  */
  minRent?: number;

  /**  */
  maxRent?: number;
}

export interface IntegrationDTO {
  /**  */
  id?: string;

  /**  */
  integrationType?: SystemEnum;

  /**  */
  yardi?: IntegrationDetailsYardiDTO;

  /**  */
  rentCafe?: IntegrationDetailsRentCafeDTO;

  /**  */
  enablements?: IntegrationEnablementsDTO;

  /**  */
  repositoryDelivery?: IntegrationRepositoryDeliveryDTO;
}

export interface IntegrationDetailsRentCafeDTO {
  /**  */
  companyCode?: string;

  /**  */
  propertyId?: number;

  /**  */
  propertyCode?: string;

  /**  */
  apiToken?: string;
}

export interface IntegrationDetailsYardiDTO {
  /**  */
  propertyId?: string;

  /**  */
  database?: string;

  /**  */
  platform?: string;

  /**  */
  serverName?: string;

  /**  */
  endpointAddress?: string;
}

export interface IntegrationEnablementsDTO {
  /**  */
  insertProspects?: boolean;

  /**  */
  syncPropertyOfficeHours?: boolean;

  /**  */
  syncSourceReferrals?: boolean;

  /**  */
  syncPropertyInfo?: boolean;

  /**  */
  syncFloorplans?: boolean;

  /**  */
  syncAmenities?: boolean;

  /**  */
  syncUnits?: boolean;
}

export interface IntegrationRepositoryDeliveryDTO {
  /**  */
  propertyInfo?: boolean;

  /**  */
  units?: boolean;

  /**  */
  floorplans?: boolean;

  /**  */
  amenities?: boolean;

  /**  */
  officeHours?: boolean;

  /**  */
  sourceReferrals?: boolean;
}

export interface PropertyAdminDetailsDTO {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  integrations?: IntegrationDTO[];
}

export interface PropertyDetailsModel {
  /**  */
  name?: string;

  /**  */
  address?: AddressInfoModel;

  /**  */
  website?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;
}

export interface PropertyOfficeHoursModel {
  /**  */
  startDayOfWeek?: number;

  /**  */
  endDayOfWeek?: number;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;

  /**  */
  description?: string;
}

export interface SourceReferralModel {
  /**  */
  sourceName?: string;

  /**  */
  phoneNumber?: string;
}

export interface UnitModel {
  /**  */
  id?: string;

  /**  */
  marketRent?: string;

  /**  */
  isAvailable?: boolean;

  /**  */
  vacantDate?: Date;

  /**  */
  madeReadyDate?: Date;

  /**  */
  floorplan?: FloorplanModel;
}

export type AmenityLevelEnum = 0 | 1;

export type SystemEnum = 0 | 1 | 2;
