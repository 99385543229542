import * as React from "react";
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { useMediaQuery } from '../hooks/useMediaQuery';

export const FloorPlan = (props) => {
  const planInfo = useSelector(state => state.api.floors)[props.plan]

  const svgPath = `/floor_plates/FloorPlate${props.floorLevel}.svg`

  const isSmallerThan480px = useMediaQuery('(max-width: 480px)');

  const handleSVGLoad = (svg) => {
      props.setSvgLoaded(true)
    planInfo.units.forEach(item => {
      const pathId = item.id.slice(5,7)
      const pathElement = svg.querySelector(`path[id^="${pathId}-"]`);
    
      if (pathElement && !item.isAvailable) {
        pathElement.setAttribute("fill", "#fff");
      }
       if (pathElement && item.isAvailable) {
        pathElement.setAttribute("fill", "#F0B043");
        pathElement.setAttribute("fill-opacity", "0.25");
      }
    });
  };


  return (
        <ReactSVG 
          src={svgPath} 
          beforeInjection={(svg) => {
            svg.setAttribute('width', isSmallerThan480px ? '100%':'75%') 
            svg.setAttribute('height','auto')
            svg.addEventListener('mouseover', props.onMouseOver);
            svg.addEventListener('click', props.onClick);
          }} 
           afterInjection={(svg ) => {
            handleSVGLoad(svg)
            props.setPageLoaded(true)
          }}
        />
   

)};