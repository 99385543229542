import React, { useMemo } from "react";
import {readableDate} from "../helpers/readableDate";
import { useNavigate } from "react-router-dom";
// import plate from './plate.svg';

const data = {
  title: "Unit 19",
  variant: "Floor #",
  rooms: {
    bedrooms: "2 Bedroom",
    bathroom: "2 Bathroom",
  },
  area: "0000 SQ FT",
  availability_date: "00.00.2023",
  price: {
    min: "$0000.00",
    max: null,
  },
  image: {
    url: "",
  },
};

const InfoCard = ({inputData, pdfLink}) => {
  const navigate = useNavigate();

  const handleContactUs = () => {
    navigate("/contact");
  }
  if (!inputData) {
    return null;
  }



  let formattedDate = readableDate(inputData.madeReadyDate)
  
  return (
    <div className="wrapper-card">
      <div className="card-container">
        <div className="card-title">
          <h2>Unit {inputData.id}</h2>
          <p>{data.variant} {inputData.id.slice(3,5)}</p>
        </div>

        <div className="card-apartment">
          <p>Apartment Type</p>
          <div>
            <span>{0 || inputData.floorplan.bedCount } Bedroom</span>
            <hr />
            <span>{0 || inputData.floorplan.bathCount} Bathroom</span>
          </div>
        </div>

        <div className="card-middle">
          <div className="card-area">
            <p>Total Area</p>
            <span>{inputData.floorplan.maxSquareFeet} SQ FT </span>
          </div>

          <div className="card-prices">
            <p>Price</p>
            <div>
              {
                inputData.floorplan.minRent === -1 ?
                (

                  <span>${inputData.marketRent} </span>
                ) : (
                  <>
                  <span>${inputData.floorplan.minRent}.00 </span>
                  {inputData.floorplan.maxRent && (
                    <React.Fragment>
                      <span>- </span>
                      <span>${inputData.floorplan.maxRent}.00</span>
                    </React.Fragment>
                  )}
                  </>
                )
              }
            </div>
          </div>

          <div className="card-availability">
            <p>Availability Date</p>
            <span>{formattedDate}</span>
          </div>
        </div>

        {/*        <div className="card-preview-image">
          <img src={plate} alt="preview_image" />
        </div>*/}

        <div className="card-buttonContainer">
          <div className="card-buttonContainer-download">
              <a href={pdfLink} download={pdfLink} target='_blank'>
                download pdf
              </a>
          </div>
          <div onClick={handleContactUs} className="card-buttonContainer-button">
            <button>apply now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
