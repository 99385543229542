import { LinearInterpolator, FlyToInterpolator } from "@deck.gl/core";
import mapController from "@deck.gl/core/dist/es5/controllers/map-controller";
import { DeckGL } from "@deck.gl/react";
import { createContext, useCallback, useEffect, useState } from "react";
import { useDirection } from "../hooks/useDirection";

const DeckGLContext = createContext({});
const transitionInterpolator = new FlyToInterpolator(['bearing', 'pitch', 'zoom']);

const cameraStart = {
  latitude: 26.034946,  
  longitude: -80.144144,
  
  zoom: 13,
  transitionInterpolator,
  transitionDuration: 5000,
  pitch: 90,
  bearing: 2,
}

const cameraEnd = {
  latitude: 26.11944269790867,
  longitude: -80.144855417212,
  zoom: 16,
  pitch: 60,
  bearing: 2,
}

const DeckGLWrapper = ({ children, mapboxLoaded, ...params }) => {
  const [viewState, setViewState] = useState(cameraStart);

  const [deck, setDeck] = useState(null)

  const value = {};

  const moveCamera = useCallback(() => {
    setViewState(viewState => ({
      ...viewState,
      ...cameraEnd,
    }))
  }, []);

  useEffect(() => {
    if (mapboxLoaded) {
      setTimeout(() => {
        moveCamera()
      }, 500)
    }
  }, [mapboxLoaded])
  
  return (
    <DeckGLContext.Provider value={value}>
      <DeckGL
        ref={setDeck}
        {...params}
        initialViewState={viewState}
        controller={{inertia: 300, scrollZoom: {smooth: true, speed: 0.007}}}
      >
        {children}
      </DeckGL>
    </DeckGLContext.Provider>
  );
};

export { DeckGLWrapper };
