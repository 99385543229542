import React from 'react'

export const SelectedAmenity = ({ amenity, setSelectedPano }) => (
  <>
    <div className='selectedAmenity_caption'>
      Amenity {amenity}
    </div>
    <div className='selectedAmenity_info'>
      Floor 18
    </div>
    <button className='selectedAmenity_button' onClick={() => setSelectedPano(1)}>
      VISIT
    </button>
  </>
)

