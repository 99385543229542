import { TripsLayer } from "@deck.gl/geo-layers";
import anime from "animejs";
import { useEffect, useMemo, useRef, useState } from "react";
import { TOKEN } from "../constants";

const getLink = (a, b) =>
  `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${a[1]},${a[0]};${b[1]},${b[0]}?geometries=geojson&access_token=${TOKEN}`;


  const d = [
    {
      "waypoints": [
        {
          "coordinates": [
            -122.39079879999997,
            37.7664413
          ],
          "timestamp": 1554772579000
        },
        {
          "coordinates": [
            -122.3908298,
            37.7667706
          ],
          "timestamp": 1554772579009
        },
        {
          "coordinates": [
            -122.39271759999997,
            37.7667484
          ],
          "timestamp": 1554772579054
        },
        {
          "coordinates": [
            -122.3951341,
            37.7665964
          ],
          "timestamp": 1554772579092
        },
        {
          "coordinates": [
            -122.409425,
            37.7779834
          ],
          "timestamp": 1554772579345
        },
        {
          "coordinates": [
            -122.41318080000002,
            37.7750068
          ],
          "timestamp": 1554772579402
        },
        {
          "coordinates": [
            -122.41619750000001,
            37.7774034
          ],
          "timestamp": 1554772579462
        },
        {
          "coordinates": [
            -122.42135359999997,
            37.7770974
          ],
          "timestamp": 1554772579563
        },
        {
          "coordinates": [
            -122.42620490000002,
            37.8010553
          ],
          "timestamp": 1554772579880
        },
        {
          "coordinates": [
            -122.44484019999999,
            37.7989071
          ],
          "timestamp": 1554772580070
        },
        {
          "coordinates": [
            -122.4493488,
            37.801993
          ],
          "timestamp": 1554772580117
        },
        {
          "coordinates": [
            -122.44985459999998,
            37.8024803
          ],
          "timestamp": 1554772580120
        },
        {
          "coordinates": [
            -122.45090290000002,
            37.8033639
          ],
          "timestamp": 1554772580127
        },
        {
          "coordinates": [
            -122.45116330000002,
            37.8034643
          ],
          "timestamp": 1554772580130
        },
        {
          "coordinates": [
            -122.44840979999998,
            37.8046164
          ],
          "timestamp": 1554772580166
        },
        {
          "coordinates": [
            -122.44826899999998,
            37.8045327
          ],
          "timestamp": 1554772580176
        },
        {
          "coordinates": [
            -122.44827479999998,
            37.8044851
          ],
          "timestamp": 1554772580181
        },
        {
          "coordinates": [
            -122.44846849999999,
            37.8043839
          ],
          "timestamp": 1554772580186
        },
        {
          "coordinates": [
            -122.44856720000001,
            37.8040182
          ],
          "timestamp": 1554772580200
        }
      ]
    }
  ]

const useDirection = (a, b) => {
  const [dir, setDir] = useState(null);

  const [timeAlpha, setTimeAlpha] = useState(0)
  const _anim = useRef(null)

  const startAnim = () => {
    _anim.current?.pause()
    setTimeAlpha(0)
    
    const current = { time: 0 } 
    
    _anim.current = anime({
      targets: current,
      time: 100,
      duration: 1000,
      easing: 'linear',
      update: () => {
        setTimeAlpha(current.time)
      }
    })
  }
  
  useEffect(() => {
    if (a && b) {
      const r = async () => {
        const link = getLink(a, b);

        const res = await fetch(link).then((res) => res.json());

        startAnim()
        setDir(res);
      };

      r();
    }
    
  }, [a, b]);

  const points = dir?.routes[0]?.geometry?.coordinates || [];

  const obj = [
    {
      waypoints: points.map((el, i) => ({
        coordinates: el,
        timestamp: i,
      })),
    },
  ];

  const l = new TripsLayer({
    id: "trips-layer",
    data: obj,
    getPath: d => d.waypoints.map(p => p.coordinates),
    getTimestamps: d => {
      return d.waypoints.map((p, i, arr) => ((i + 1) / arr.length) * 100)
    },
    getColor: () => [253, 0, 0],

    capRounded: true,
    jointRounded: true,
    // trailLength: 1600,
    widthMinPixels: 6,
    opacity: 1,
    // currentTime: time,
    currentTime: timeAlpha,

  });

  return l;
};

export { useDirection };
