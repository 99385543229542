import React from 'react';
import { motion, AnimatePresence } from "framer-motion"
import {useAppSelector} from "../../store/store.utils";

export const RoomInfo = ({ hoveredRoom, plan, position }) => {

  const floors = useAppSelector(state => state.api.floors)

  if (plan && hoveredRoom) {
    let correctedPlan = plan <= 9 ? '0' + plan : plan
    let slId = `SL-${correctedPlan}${hoveredRoom}`
    let hoveredRoomData = floors[correctedPlan].units.find(unit => unit.id === slId)

    if (hoveredRoomData) {
      return (
          <div
              style={position && { top: position.y + 10, left: position.x + 10 }}
              className="PlanViewer_infoContainer"
          >
            <AnimatePresence>
              {hoveredRoom !== null ?
                  <motion.div
                      className='PlanViewer_roomInfo'
                      initial={{opacity: 0}}
                      animate={{opacity: 1}}
                      transition={{duration: 0.2}}
                      exit={{opacity: 0}}
                  >
                    <div className='PlanViewer_roomInfo-caption'>
                      Apartment #{hoveredRoom.split('room').pop()}
                    </div>
                    <div className='PlanViewer_roomInfo-info'>
                      {hoveredRoomData.floorplan.bedCount} {hoveredRoomData.floorplan.bedCount <= 1 ? 'Bedroom' : 'Bedrooms'}
                      <p style={{opacity: '10%'}}>|</p>
                      {hoveredRoomData.floorplan.bedCount} {hoveredRoomData.floorplan.bedCount <= 1 ? 'Bathroom' : 'Bathrooms'}
                    </div>
                    {/* <div className='PlanViewer_roomInfo-price'>
            <TagFilled style={{transform: 'scaleX(-1)', marginRight: '5px', color: '#8FADAE'}}/>
            $1950 - $2400
          </div> */}
                  </motion.div>
                  : ''}
            </AnimatePresence>
          </div>
      )
    }
  }

  return null
}
