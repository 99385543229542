type FloorPlan = {
  img: string;
  pdfLink: string;
  tourLink: {
    Unfurnished: string
    Scandinavian: string
    Contemporary: string
  }
};

type FloorPlans = {
  [key: string]: FloorPlan;
};

export const FLOORLANS_IMAGES_TOURS: FloorPlans = {
  4362504: {
    img: '/floorplans/1.png',
    pdfLink: '/pdf/1.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ff7344ad-9996-4812-abe3-216a8f399bb0/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ed8200ce-32f3-4641-a888-cc9bf7379321/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/842c279f-2601-426c-8078-41d3171832b4/tour',
    }
  },
  4362505: {
    img: '/floorplans/2.png',
    pdfLink: '/pdf/2.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ff7344ad-9996-4812-abe3-216a8f399bb0/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ed8200ce-32f3-4641-a888-cc9bf7379321/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/842c279f-2601-426c-8078-41d3171832b4/tour',
    }
  },
  4362506: {
    img: '/floorplans/3.png',
    pdfLink: '/pdf/3.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/b0adaa54-e4ea-4b6e-b2e7-a3832606e5fe/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/861d63a4-65e2-4f1b-bc85-2421a033a8ea/tour ',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/40f123c0-5095-40eb-83b1-b8a9f4c1ebb8/tour',
    }
  },
  4362501: {
    img: '/floorplans/4.png',
    pdfLink: '/pdf/4.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362524: {
    img: '/floorplans/6.png',
    pdfLink: '/pdf/6.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/b0adaa54-e4ea-4b6e-b2e7-a3832606e5fe/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/861d63a4-65e2-4f1b-bc85-2421a033a8ea/tour ',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/40f123c0-5095-40eb-83b1-b8a9f4c1ebb8/tour',
    }
  },
  4362507: {
    img: '/floorplans/7.png',
    pdfLink: '/pdf/7.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/b0adaa54-e4ea-4b6e-b2e7-a3832606e5fe/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/861d63a4-65e2-4f1b-bc85-2421a033a8ea/tour ',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/40f123c0-5095-40eb-83b1-b8a9f4c1ebb8/tour',
    }
  },
  4362526: {
    img: '/floorplans/8.png',
    pdfLink: '/pdf/8.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/b0adaa54-e4ea-4b6e-b2e7-a3832606e5fe/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/861d63a4-65e2-4f1b-bc85-2421a033a8ea/tour ',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/40f123c0-5095-40eb-83b1-b8a9f4c1ebb8/tour',
    }
  },
  4362527: {
    img: '/floorplans/9.png',
    pdfLink: '/pdf/9.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-j3SftvTUs/viewonly/style/71973e5f-c6c1-4144-b567-ede004491826/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-j3SftvTUs/viewonly/style/4eccf99d-25c4-4221-87c4-9eb88c9bdc5f/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-j3SftvTUs/viewonly/style/90995d58-c376-4a0f-9538-de5d1fa78dc2/tour',
    }
  },
  4362502: {
    img: '/floorplans/10.png',
    pdfLink: '/pdf/10.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-61mSIDg-4/viewonly/style/1eda1230-cc0d-4d91-aeb1-a656083e3fc3/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-61mSIDg-4/viewonly/style/ff0055ea-8c02-449b-8b90-3557ea00d3b9/tour ',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-61mSIDg-4/viewonly/style/93742333-9d85-4795-930f-8741f66e8aaf/tour',
    }
  },
  4362508: {
    img: '/floorplans/11.png',
    pdfLink: '/pdf/11.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362509: {
    img: '/floorplans/12.png',
    pdfLink: '/pdf/12.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362510: {
    img: '/floorplans/13.png',
    pdfLink: '/pdf/13.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/b0adaa54-e4ea-4b6e-b2e7-a3832606e5fe/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/861d63a4-65e2-4f1b-bc85-2421a033a8ea/tour ',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/40f123c0-5095-40eb-83b1-b8a9f4c1ebb8/tour',
    }
  },
  4362511: {
    img: '/floorplans/14.png',
    pdfLink: '/pdf/14.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362512: {
    img: '/floorplans/15.png',
    pdfLink: '/pdf/15.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362513: {
    img: '/floorplans/16.png',
    pdfLink: '/pdf/16.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362514: {
    img: '/floorplans/17.png',
    pdfLink: '/pdf/17.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362515: {
    img: '/floorplans/18.png',
    pdfLink: '/pdf/18.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/f0104093-97a3-4891-b5b6-a438e44a2ddb/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/fb5db988-775a-4f7e-b43e-cbace2498d23/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-svPtP89IN/viewonly/style/04b881ae-15a0-4eaf-918c-af48663e54d4/tour',
    }
  },
  4362528: {
    img: '/floorplans/19.png',
    pdfLink: '/pdf/19.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-7UE7eTNAl/viewonly/style/4f9b0543-ae93-4425-bb93-77d8f13bfc05/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-7UE7eTNAl/viewonly/style/2f091f15-d33e-4d5d-81e0-38280d660873/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-7UE7eTNAl/viewonly/style/4bbc66cc-2b0f-4d06-8160-3caaba3bfd71/tour',
    }
  },
  4362517: {
    img: '/floorplans/21.png',
    pdfLink: '/pdf/21.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/b0adaa54-e4ea-4b6e-b2e7-a3832606e5fe/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/861d63a4-65e2-4f1b-bc85-2421a033a8ea/tour ',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-YACGePvEG/viewonly/style/40f123c0-5095-40eb-83b1-b8a9f4c1ebb8/tour',
    }
  },
  4362503: {
    img: '/floorplans/22.png',
    pdfLink: '/pdf/22.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-61mSIDg-4/viewonly/style/1eda1230-cc0d-4d91-aeb1-a656083e3fc3/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-61mSIDg-4/viewonly/style/ff0055ea-8c02-449b-8b90-3557ea00d3b9/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-61mSIDg-4/viewonly/style/93742333-9d85-4795-930f-8741f66e8aaf/tour',
    }
  },
  4362529: {
    img: '/floorplans/23.png',
    pdfLink: '/pdf/23.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-mLUjCU3Uq/viewonly/style/96e71b58-458a-4b6d-a5e0-2e47ee1b03dd/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-mLUjCU3Uq/viewonly/style/2a0971af-d952-4486-9a90-40643678f5f9/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-mLUjCU3Uq/viewonly/style/5db100b7-c40d-47ee-9db8-870a7cbf3a5a/tour',
    }
  },
  4362520: {
    img: '/floorplans/24.png',
    pdfLink: '/pdf/24.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ff7344ad-9996-4812-abe3-216a8f399bb0/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ed8200ce-32f3-4641-a888-cc9bf7379321/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/842c279f-2601-426c-8078-41d3171832b4/tour',
    }
  },
  4362521: {
    img: '/floorplans/25.png',
    pdfLink: '/pdf/25.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ff7344ad-9996-4812-abe3-216a8f399bb0/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ed8200ce-32f3-4641-a888-cc9bf7379321/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/842c279f-2601-426c-8078-41d3171832b4/tour',
    }
  },
  4362523: {
    img: '/floorplans/26.png',
    pdfLink: '/pdf/26.pdf',
     tourLink: {
      Unfurnished: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ff7344ad-9996-4812-abe3-216a8f399bb0/tour',
      Scandinavian: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/ed8200ce-32f3-4641-a888-cc9bf7379321/tour',
      Contemporary: 'https://wework-planner-dev.vercel.app/tour/ww-oDP6X9ZM0/viewonly/style/842c279f-2601-426c-8078-41d3171832b4/tour',
    }
  }
}