import { FloorplanModel, FloorplansService, ProspectService, UnitModel, UnitsService } from "../api/api.properties";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";


interface IFloors {
    [key: string]: {
        units: Array<UnitModel>
    }
}

export interface IApiState {
    activeUnit: UnitModel | null
    floorplans: Array<FloorplanModel>,
    units: Array<UnitModel>,
    floors: IFloors
    isLoading: boolean
    error: string | null
}

const initialState: IApiState = {
    activeUnit: null,
    units: [],
    floorplans: [],
    floors: {},
    isLoading: false,
    error: null
}

interface IContactUsForm {
    /**  */
    genericPropertyId: string;
    /**  */
    firstName?: string;
    /**  */
    lastName?: string;
    /**  */
    email?: string;
    /**  */
    phone?: string;
    /**  */
    enableTextMessages?: boolean;
    /**  */
    message?: string;
    /**  */
    source?: string;
}

const asyncActions = {
    sendContactUs: createAsyncThunk<
        Array<FloorplanModel>,
        IContactUsForm,
        { state: RootState }
    >('api/sendContactUs', async (payload, { rejectWithValue }) => {

        try {
            const response = await ProspectService.contactUs(payload)
            return response
        } catch (error) {
            console.log('@test', error)
            return rejectWithValue('An error occurred while send contact us form.')
        }
    }),
    loadFloorplans: createAsyncThunk<
        Array<FloorplanModel>,
        string,
        { state: RootState }
    >('api/loadFloorplans', async (payload, { rejectWithValue }) => {

        try {
            const response = await FloorplansService.floorplans({ genericPropertyId: payload })
            return response
        } catch (error) {
            console.log('@test', error)
            return rejectWithValue('An error occurred while fetching floorplans.')
        }
    }),
    loadUnits: createAsyncThunk<
        Array<UnitModel>,
        { id: string, floorplans: Array<FloorplanModel> },
        { state: RootState }
    >('api/loadUnits', async (payload, { rejectWithValue }) => {

        try {
            const restrictedFloorplans = [4362525, 4362518, 4362519, 4362522];
            const { floorplans, id } = payload;
            console.log(floorplans)
            const promises = floorplans.map(async (floorplan) => {
                if (!restrictedFloorplans.includes(parseInt(floorplan.id!))) {
                    const response = await UnitsService.units({
                        genericPropertyId: id,
                        floorplanId: floorplan.id,
                    });
                    response.forEach((unit) => (unit.floorplan = floorplan));
                    return response;
                } else {
                    return [];
                }
            });

            const results = await Promise.all(promises);

            const units = results.flat();

            return units;
        } catch (error) {
            console.log('@test', error)
            return rejectWithValue('An error occurred while fetching units.')
        }
    })
}

export const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<Partial<IApiState>>) => {
            Object.keys(action.payload).forEach(key => {
                //  @ts-ignore
                state[key] = action.payload[key]
            })
        },
    },
    extraReducers: builder => {
        builder.addCase(asyncActions.sendContactUs.pending, state => {
            state.error = null
            state.isLoading = true
        })
        builder.addCase(asyncActions.sendContactUs.fulfilled, state => {
            state.error = null
            state.isLoading = false
        })
        builder.addCase(asyncActions.sendContactUs.rejected, (state, action) => {
            state.error = action.payload as string
            state.isLoading = false
        })
        builder.addCase(asyncActions.loadFloorplans.pending, state => {
            state.error = null
            state.isLoading = true
            state.floorplans = []
        })
        builder.addCase(asyncActions.loadFloorplans.fulfilled, (state, action) => {
            state.error = null
            state.isLoading = false
            state.floorplans = action.payload
        })
        builder.addCase(asyncActions.loadFloorplans.rejected, (state, action) => {
            state.error = action.payload as string
            state.isLoading = false
            state.floorplans = []
        })

        builder.addCase(asyncActions.loadUnits.pending, state => {
            state.error = null
            state.isLoading = true
            state.units = []
        })
        builder.addCase(asyncActions.loadUnits.fulfilled, (state, action) => {
            state.error = null
            state.isLoading = false
            state.units = action.payload

            let floors: IFloors = {}

            for (let i = 0; i <= 34; i++) {
                let floorId: string = i <= 9 ? '0' + i : i.toString()
                floors[floorId] = {
                    units: []
                }
            }

            action.payload.forEach((unit: UnitModel) => {
                if (typeof unit.id !== "undefined") {
                    floors[unit.id.slice(3, 5)]?.units.push(unit)
                }
            })

            console.log('@R: floors', floors)
            state.floors = floors
        })
        builder.addCase(asyncActions.loadUnits.rejected, (state, action) => {
            state.error = action.payload as string
            state.isLoading = false
            state.units = []
        })
    }
})

export const $api_actions = {
    ...apiSlice.actions,
    ...asyncActions,
}

export default apiSlice.reducer
