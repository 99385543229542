import React from 'react'
import {useGLTF, Html, Cloud} from '@react-three/drei'
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import {useFrame, useThree} from 'react-three-fiber';
import {Water} from './waterShader/Water2';
import {SelectedAmenity} from './components/SelectedAmenity';
import {AnimatePresence, motion} from 'framer-motion';
import {useAppSelector} from "./store/store.utils";
import {applyFilters} from "./helpers/unitFilters";

export const HouseModel = ({
                               setPlan,
                               setHovered,
                               amenitiesView,
                               setSelectedAmenity,
                               selectedAmenity,
                               setSelectedPano,
                               footerHovered,
                               availableFloors,
                               setAvailableFloors
                           }) => {
    const {nodes, materials} = useGLTF('./house.glb');
    const [hoveredAmenity, setHoveredAmenity] = React.useState(null);

    const amenitiesSelectedMaterial = new THREE.MeshStandardMaterial({
        side: THREE.DoubleSide,
        color: 0xeb8142,
        aoMapIntensity: 0.6,
        aoMap: materials.Amenities.aoMap,
        name: 'Amenities',
    });

    const waterRef = React.useRef(null);
    const three = useThree();

    function checkAvailable(name) {
        return availableFloors.some(floor => name === `floor${floor}`);
    }

    React.useEffect(() => {
        const waterPlane = waterRef.current;
        const water = new Water(
            waterPlane.geometry.clone(),
            {
                textureWidth: 512,
                textureHeight: 512,
                scale: 2,
                flowSpeed: 0.04,
                color: 0xa1e3ff,
                reflectivity: 0.3,
                flowMap: new THREE.TextureLoader().load('./flowMap.png'),
            }
        );
        waterPlane.visible = false;
        water.rotation.x = -Math.PI / 2;
        water.scale.copy(waterPlane.scale)
        water.position.x += 38;
        water.material.side = THREE.DoubleSide;
        waterPlane.parent.add(water);
    }, []);

    const hoveredFloorRef = React.useRef(null)
    const houseFunctions = {
        onMove: (e) => {
            e.stopPropagation();
            const {intersections} = e;
            const floor = intersections.find(e => e.object.name.includes('floor'));

            if (floor && hoveredFloorRef.current && floor.object.name !== hoveredFloorRef.current.name) {
                if (checkAvailable(hoveredFloorRef.current.name)) {
                    hoveredFloorRef.current.material.color.setRGB(0.7, 0.7, 0.7)
                }
            }

            if (floor && checkAvailable(floor.object.name)) {
                document.body.style.cursor = 'pointer';
                floor.object.material.color.setHex(0xF0B143)
                setHovered(getNum(floor.object.name))
                hoveredFloorRef.current = floor.object
            }
        },

        onOut: (e) => {
            if (hoveredFloorRef.current) {
                if (checkAvailable(hoveredFloorRef.current.name)) {
                    hoveredFloorRef.current.material.color.setRGB(0.7, 0.7, 0.7)
                }
            }
            hoveredFloorRef.current = null
            document.body.style.cursor = '';
            setHovered(null)
        },

        onClick: (e) => {
            e.stopPropagation();
            const {intersections} = e;
            const floor = intersections.find(e => e.object.name.includes('floor'));
            if (floor && checkAvailable(floor.object.name)) {
                setPlan(getNum(floor.object.name))
            }
        }
    };

    const amenitiesFunctions = {
        onOver: (e) => {
            document.body.style.cursor = 'pointer';
            setHoveredAmenity(e.object.name);
        },
        onOut: (e) => {
            setHoveredAmenity(null);
            document.body.style.cursor = '';
        },
        onClick: (e) => {
            setSelectedAmenity(prev => prev === e.object.name ? null : e.object.name);
        }
    }

    materials.Building = new THREE.MeshStandardMaterial().copy(materials.Building);
    materials.Building.depthWrite = true;
    materials.Building_inner = new THREE.MeshStandardMaterial().copy(materials.Building_inner);

    materials.Building.transparent = true;
    materials.Building_inner.transparent = true;

    if (amenitiesView) {

        new TWEEN.Tween({opacity: materials.Building.opacity})
            .to({opacity: 0.05}, 350)
            .onUpdate((props) => {
                materials.Building.opacity = props.opacity;
                materials.Building_inner.opacity = props.opacity;
            })
            .start();
    } else {
        new TWEEN.Tween({opacity: materials.Building.opacity})
            .to({opacity: 1}, 350)
            .onUpdate((props) => {
                materials.Building.opacity = props.opacity;
                materials.Building_inner.opacity = props.opacity;
            })
            .start();
    }

    useFrame(({scene}) => {
        if (footerHovered !== null) {
            scene.traverse(obj => {
                if (obj.name !== footerHovered) {
                    if (obj.material && (
                        obj.material.name === 'Building' ||
                        obj.material.name === 'Building_inner' ||
                        obj.name.includes('Amenities')
                    )) {
                        obj.material = new THREE.MeshStandardMaterial().copy(obj.material);
                        obj.material.opacity = 0.05;
                        obj.material.transparent = true;
                    }
                } else {
                    obj.material.visible = true;
                }
            })
        } else {
            scene.traverse((obj) => {
                if (obj.name === 'Virtual_tours' || obj.name === 'Views') {
                    obj.material.visible = false;
                }
                if (obj.name.includes('Amenities')) {
                    obj.material = materials.Amenities;
                    if (obj.name === selectedAmenity || obj.name === hoveredAmenity) {
                        obj.material = amenitiesSelectedMaterial;
                    }
                }
            })
        }

        TWEEN.update();
    });

    function footerHighilght(name, color) {
        three.scene.userData.isHovering = true;
        three.scene.traverse(obj => {
            if (obj.name === name) {
                obj.material = new THREE.MeshStandardMaterial(obj.material.clone);
                obj.material.color = color;
                obj.aoMapIntensity = 0.6;
            } else {
                if (obj.material && (obj.material.name === 'Building' || obj.material.name === 'Building_inner')) {
                    obj.material = new THREE.MeshStandardMaterial(obj.material.clone());
                    obj.material.color = 0xffffff;
                    obj.material.opacity = 0.05
                }
            }
        })
    };

    function footerReset() {
        three.scene.userData.isHovering = false;
    }

    const amenitiesHtml = [];
    for (const prop in nodes) {
        if (prop.includes('Amenities')) {
            amenitiesHtml.push(
                <mesh position={getCenterPoint(nodes[prop])}>
                    <Html>
                        <AnimatePresence>
                            {selectedAmenity === prop &&
                                <motion.div
                                    className="selectedAmenity"
                                    initial={{opacity: 0, scale: 0.6}}
                                    animate={{opacity: 1, scale: 1}}
                                    transition={{duration: 0.3}}
                                    exit={{opacity: 0, scale: 0.3}}
                                >
                                    <SelectedAmenity amenity={+selectedAmenity.split('Amenities_').pop()}
                                                     setSelectedPano={setSelectedPano}/>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </Html>
                </mesh>
            )
        }
    }

    const floorsRef = React.useRef(null)
    const floors = useAppSelector(state => state.api.floors)
    const priceFilter = useAppSelector(state => state.filter.price)
    const availabilityFilter = useAppSelector(state => state.filter.availability)
    const typeFilter = useAppSelector(state => state.filter.type)
    const bathroomsFilter = useAppSelector(state => state.filter.bathrooms)


    React.useEffect(() => {
        let tempAvailableFloors = []
        for (let floor in floors) {
            applyFilters(floors[floor].units, typeFilter, bathroomsFilter, availabilityFilter, priceFilter)
                .some(unit => unit.isAvailable) && tempAvailableFloors.push(parseInt(floor))
        }
        setAvailableFloors(tempAvailableFloors)
    }, [floors, priceFilter, availabilityFilter, typeFilter, bathroomsFilter])

    React.useEffect(() => {
        if (floorsRef.current) {
            floorsRef.current.traverse((el) => {
                if (el.name.includes('floor')) {
                    el.material = new THREE.MeshStandardMaterial().copy(materials.Building)
                    const isAvailable = checkAvailable(el.name)
                    if (!isAvailable) {
                        el.material.color.setRGB(0.3, 0.3, 0.3)
                    }
                }
            })
        }
    }, [floorsRef, availableFloors])

    return (
        <group dispose={null}>
            <group name="Scene" rotation={[0, 0, 0]}>
                <group name="house" rotation={[0, 0, 0]} ref={floorsRef}
                       onPointerMove={!amenitiesView ? houseFunctions.onMove : () => {
                       }}
                       onPointerOut={!amenitiesView ? houseFunctions.onOut : () => {
                       }}
                       onPointerDown={(event) => {
                           window._left = event.pageX;
                           window._top = event.pageY;
                       }}
                       onClick={(event) => {
                           var deltaX = event.pageX - window._left;
                           var deltaY = event.pageY - window._top;
                           var euclidean = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

                           if (euclidean < 5) {
                               !amenitiesView && houseFunctions.onClick(event);
                           }
                       }}
                >
                    <Cloud position={[100, 80, 56]} scale={5} depthTest={false} opacity={0.35}/>
                    <Cloud position={[30, 60, -120]} scale={5} depthTest={false} opacity={0.35}/>
                    <Cloud position={[190, 123, -130]} scale={5} depthTest={false} opacity={0.35}/>
                    <Cloud position={[-69, 80, -26]} scale={5} depthTest={false} opacity={0.35}/>
                    <Cloud position={[165, 80, 80]} scale={5} depthTest={false} opacity={0.35}/>
                    <Cloud position={[50, 80, 120]} scale={5} depthTest={false} opacity={0.35}/>
                    <Cloud position={[97, 80, 136]} scale={5} depthTest={false} opacity={0.35}/>
                    <group
                        onPointerOver={amenitiesFunctions.onOver}
                        onPointerOut={amenitiesFunctions.onOut}
                        onPointerDown={(event) => {
                            window._left = event.pageX;
                            window._top = event.pageY;
                        }}
                        onClick={(event) => {
                            var deltaX = event.pageX - window._left;
                            var deltaY = event.pageY - window._top;
                            var euclidean = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

                            if (euclidean < 5) {
                                amenitiesFunctions.onClick(event)
                            }
                        }}
                    >
                        <mesh name="Amenities_01" geometry={nodes.Amenities_01.geometry} material={materials.Amenities}
                              position={[80.04, 40, -83.78]} rotation={[0, 0.03, 0]} scale={[7.11, 1.78, 7.11]}/>
                        <mesh name="Amenities_02" geometry={nodes.Amenities_02.geometry} material={materials.Amenities}
                              position={[80.04, 34.16, -83.53]} rotation={[0, 0.03, 0]} scale={[7.11, 1.78, 7.11]}/>
                        <mesh name="Amenities_03" geometry={nodes.Amenities_03.geometry} material={materials.Amenities}
                              position={[80.04, 34.16, -83.78]} rotation={[0, 0.03, 0]} scale={[7.11, 1.78, 7.11]}/>
                    </group>

                    <mesh name="Water" geometry={nodes.Water.geometry} ref={waterRef} material={nodes.Water.material}
                          position={[38.23, -0.32, 62.93]} rotation={[Math.PI / 2, 0, 0]} scale={[436.1, 314.78, 1]}/>
                    <mesh name="WaterUnder" geometry={nodes.WaterUnder.geometry} material={nodes.WaterUnder.material}
                          position={[38.23, -4.84, 62.93]} rotation={[Math.PI / 2, 0, 0]} scale={[436.1, 314.78, 1]}/>
                    <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials.Material_0}
                          position={[49.11, 146.89, 3.38]} rotation={[Math.PI, 0, 0]} scale={600}/>

                    {amenitiesHtml}

                    <mesh name="Edges" geometry={nodes.Edges.geometry} material={nodes.Edges.material}
                          position={[-180.5, -3.11, -12]} rotation={[0, Math.PI / 2, 0]} scale={[113.94, 8.38, 2.46]}/>
                    <mesh name="Edges_1" geometry={nodes.Edges_1.geometry} material={nodes.Edges_1.material}
                          position={[254.87, -3.01, 131.76]} rotation={[0, Math.PI / 2, 0]}
                          scale={[113.94, 8.38, 2.46]}/>
                    <mesh name="Test_fits_01" geometry={nodes.Test_fits_01.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_02" geometry={nodes.Test_fits_02.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_03" geometry={nodes.Test_fits_03.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_04" geometry={nodes.Test_fits_04.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_05" geometry={nodes.Test_fits_05.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_06" geometry={nodes.Test_fits_06.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_07" geometry={nodes.Test_fits_07.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_08" geometry={nodes.Test_fits_08.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_09" geometry={nodes.Test_fits_09.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_10" geometry={nodes.Test_fits_10.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_11" geometry={nodes.Test_fits_11.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Test_fits_12" geometry={nodes.Test_fits_12.geometry} material={materials.Building_inner}
                          position={[81.55, 14.92, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]}
                          scale={[11.06, 44.22, 11.06]}/>
                    <mesh name="Views" geometry={nodes.Views.geometry} material={materials.Views}
                          position={[108.33, 62.49, -8.61]} rotation={[0, 0.03, 0]} scale={0.88}/>
                    <mesh name="Virtual_tours" geometry={nodes.Virtual_tours.geometry}
                          material={materials.Virtual_tours} position={[55.94, 94.05, -83.84]} rotation={[0, 0, 0]}
                          scale={0.66}/>
                    <mesh name="Cubo002" geometry={nodes.Cubo002.geometry} material={materials.Building}
                          position={[66.49, 19.87, -14.07]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo015" geometry={nodes.Cubo015.geometry} material={materials.Building}
                          position={[61.44, 68.07, -70.41]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo016" geometry={nodes.Cubo016.geometry} material={materials.Building}
                          position={[57.69, 70.67, -84.57]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo017" geometry={nodes.Cubo017.geometry} material={materials.Building}
                          position={[60.81, 70.67, -94.22]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo018" geometry={nodes.Cubo018.geometry} material={materials.Building}
                          position={[54.5, 70.67, -94.06]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo031" geometry={nodes.Cubo031.geometry} material={materials.Building}
                          position={[99.24, 56.47, -61.79]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo033" geometry={nodes.Cubo033.geometry} material={materials.Building}
                          position={[107.36, 81.2, -5.67]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo038" geometry={nodes.Cubo038.geometry} material={materials.Building}
                          position={[99.27, 42.77, -61.79]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo041" geometry={nodes.Cubo041.geometry} material={materials.Building}
                          position={[105.23, 9.07, -3.19]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo042" geometry={nodes.Cubo042.geometry} material={materials.Building}
                          position={[125.47, 21.17, -53.66]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo043" geometry={nodes.Cubo043.geometry} material={materials.Building}
                          position={[117.94, 5.88, -26.09]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo045" geometry={nodes.Cubo045.geometry} material={materials.Building}
                          position={[124.83, 65.92, -60.96]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo046" geometry={nodes.Cubo046.geometry} material={materials.Building}
                          position={[99.27, 40.17, -61.79]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo055" geometry={nodes.Cubo055.geometry} material={materials.Building}
                          position={[91.72, 93.32, -88.39]} rotation={[0, 0, 0]}/>
                    <mesh name="Cubo057" geometry={nodes.Cubo057.geometry} material={materials.Building}
                          position={[83.9, 98.49, -94.6]} rotation={[0, 0, 0]}/>
                    <mesh name="floor0" geometry={nodes.floor0.geometry} position={[87, 3.76, -49.43]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor1" geometry={nodes.floor1.geometry} position={[93.86, 6.4, -38.29]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor10" geometry={nodes.floor10.geometry} position={[82.08, 30.82, -68.03]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor11" geometry={nodes.floor11.geometry} position={[94.81, 34.86, -74.38]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor12" geometry={nodes.floor12.geometry} position={[91.05, 38.63, -68.33]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor13" geometry={nodes.floor13.geometry} position={[94.62, 41.12, -60.08]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor14" geometry={nodes.floor14.geometry} position={[94.66, 43.72, -60.08]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor15" geometry={nodes.floor15.geometry} position={[94.66, 46.32, -60.08]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor16" geometry={nodes.floor16.geometry} position={[94.66, 48.92, -60.08]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor17" geometry={nodes.floor17.geometry} position={[94.66, 51.52, -60.08]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor18" geometry={nodes.floor18.geometry} position={[94.66, 54.12, -60.08]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor19" geometry={nodes.floor19.geometry} position={[90.2, 56.96, -58.43]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor2" geometry={nodes.floor2.geometry} position={[97.78, 9.13, -40.1]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor20" geometry={nodes.floor20.geometry} position={[94.66, 59.32, -60.08]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor21" geometry={nodes.floor21.geometry} position={[94.3, 62.08, -60.39]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor22" geometry={nodes.floor22.geometry} position={[94.4, 64.67, -70.34]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor23" geometry={nodes.floor23.geometry} position={[97.28, 67.35, -70.69]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor24" geometry={nodes.floor24.geometry} position={[96.22, 70.14, -70.29]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor25" geometry={nodes.floor25.geometry} position={[90.16, 73.04, -64.76]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor26" geometry={nodes.floor26.geometry} position={[93.66, 75.29, -60.66]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor27" geometry={nodes.floor27.geometry} position={[95.3, 77.92, -59.27]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor28" geometry={nodes.floor28.geometry} position={[95.38, 80.52, -59.36]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor29" geometry={nodes.floor29.geometry} position={[95.37, 83.12, -59.36]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor3" geometry={nodes.floor3.geometry} position={[81.37, 10.92, -73.51]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor30" geometry={nodes.floor30.geometry} position={[95.38, 85.72, -59.36]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor31" geometry={nodes.floor31.geometry} position={[95.38, 88.33, -59.36]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor32" geometry={nodes.floor32.geometry} position={[95.38, 90.93, -59.36]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor33" geometry={nodes.floor33.geometry} position={[89.57, 93.7, -63.55]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor34" geometry={nodes.floor34.geometry} position={[92.24, 96.53, -68.42]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor4" geometry={nodes.floor4.geometry} position={[80.87, 14.41, -56.18]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor5" geometry={nodes.floor5.geometry} position={[81.35, 17.01, -56.74]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor6" geometry={nodes.floor6.geometry} position={[79.08, 19.59, -58.17]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor7" geometry={nodes.floor7.geometry} position={[79.08, 22.19, -58.17]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor8" geometry={nodes.floor8.geometry} position={[79.08, 24.79, -58.17]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="floor9" geometry={nodes.floor9.geometry} position={[79.45, 27.39, -58.62]}
                          rotation={[0, 0, 0]}/>
                    <mesh name="pillars0" geometry={nodes.pillars0.geometry} material={materials.Building}
                          position={[63.06, 6.03, -72.74]} rotation={[0, 0, 0]}/>
                    <mesh name="Plano" geometry={nodes.Plano.geometry} material={materials.Building}
                          position={[80.47, 10.58, -60.02]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={11.06}/>
                    <mesh name="Plano001" geometry={nodes.Plano001.geometry} material={materials.Building}
                          position={[93.02, 7.58, -53.58]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={4.44}/>
                    <mesh name="Plano006" geometry={nodes.Plano006.geometry} material={materials.Building}
                          position={[81.55, 13.58, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={10.96}/>
                    <mesh name="Plano007" geometry={nodes.Plano007.geometry} material={materials.Building}
                          position={[51.19, 74.53, -86.56]} rotation={[0, 0, 0]}/>
                    <mesh name="Plano015" geometry={nodes.Plano015.geometry} material={materials.Building}
                          position={[93.02, 4.98, -53.58]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={4.44}/>
                    <mesh name="Plano016" geometry={nodes.Plano016.geometry} material={materials.Building}
                          position={[102.46, 69.37, -52.22]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={11.06}/>
                    <mesh name="Plano017" geometry={nodes.Plano017.geometry} material={materials.Building}
                          position={[76.37, 66.49, -75.34]} rotation={[0, 0, 0]}/>
                    <mesh name="Plano023" geometry={nodes.Plano023.geometry} material={materials.Building}
                          position={[96.75, 37.17, -59.07]} rotation={[0, 0, 0]}/>
                    <mesh name="Plano025" geometry={nodes.Plano025.geometry} material={materials.Building}
                          position={[58.51, 71.97, -79.38]} rotation={[0, 0, 0]}/>
                    <mesh name="Plano026" geometry={nodes.Plano026.geometry} material={materials.Building}
                          position={[81.55, 16.18, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={10.99}/>
                    <mesh name="Plano027" geometry={nodes.Plano027.geometry} material={materials.Building}
                          position={[81.55, 18.78, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={11}/>
                    <mesh name="Plano028" geometry={nodes.Plano028.geometry} material={materials.Building}
                          position={[81.55, 21.38, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={10.98}/>
                    <mesh name="Plano029" geometry={nodes.Plano029.geometry} material={materials.Building}
                          position={[81.55, 23.98, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={10.97}/>
                    <mesh name="Plano030" geometry={nodes.Plano030.geometry} material={materials.Building}
                          position={[81.55, 26.58, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={10.92}/>
                    <mesh name="Plano032" geometry={nodes.Plano032.geometry} material={materials.Building}
                          position={[81.55, 29.18, -47.46]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={10.92}/>
                    <mesh name="Plano043" geometry={nodes.Plano043.geometry} material={materials.Building}
                          position={[81.39, 63.77, -62.84]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={11.06}/>
                    <mesh name="Plano052" geometry={nodes.Plano052.geometry} material={materials.Building}
                          position={[97.07, 95.36, -48.03]} rotation={[-Math.PI, -0.03, -Math.PI]} scale={11.06}/>
                    <mesh name="roof" geometry={nodes.roof.geometry} material={materials.Building}
                          position={[90.36, 100.15, -87.96]} rotation={[0, 0, 0]}/>
                    <mesh name="roofCube" geometry={nodes.roofCube.geometry} material={materials.Building}
                          position={[106.6, 85.58, -47.11]} rotation={[0, 0, 0]}/>
                    <mesh name="sidePanel" geometry={nodes.sidePanel.geometry} material={materials.Building}
                          position={[53.09, 21.78, -35.91]} rotation={[0, 0, 0]}/>
                    <mesh name="sideTower" geometry={nodes.sideTower.geometry} material={materials.Building}
                          position={[48.87, 31.14, -48.04]} rotation={[0, 0, 0]}/>
                    <mesh name="Surroundings" geometry={nodes.Surroundings.geometry}
                          material={materials.Surroundings_plane} position={[37.71, 3.73, 17.51]}
                          rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings018" geometry={nodes.Surroundings018.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_01" geometry={nodes.Surroundings_building_01.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_02" geometry={nodes.Surroundings_building_02.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_03" geometry={nodes.Surroundings_building_03.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_04" geometry={nodes.Surroundings_building_04.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_05" geometry={nodes.Surroundings_building_05.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_06" geometry={nodes.Surroundings_building_06.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_07" geometry={nodes.Surroundings_building_07.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_08" geometry={nodes.Surroundings_building_08.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_09" geometry={nodes.Surroundings_building_09.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_10" geometry={nodes.Surroundings_building_10.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_11" geometry={nodes.Surroundings_building_11.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_12" geometry={nodes.Surroundings_building_12.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_13" geometry={nodes.Surroundings_building_13.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_14" geometry={nodes.Surroundings_building_14.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_15" geometry={nodes.Surroundings_building_15.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_16" geometry={nodes.Surroundings_building_16.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_17" geometry={nodes.Surroundings_building_17.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_18" geometry={nodes.Surroundings_building_18.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_19" geometry={nodes.Surroundings_building_19.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_20" geometry={nodes.Surroundings_building_20.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_21" geometry={nodes.Surroundings_building_21.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_building_22" geometry={nodes.Surroundings_building_22.geometry}
                          material={materials.Surroundings} position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>
                    <mesh name="Surroundings_road" geometry={nodes.Surroundings_road.geometry} material={materials.Road}
                          position={[37.71, 3.73, 17.51]} rotation={[0, 0.01, 0]}/>

                </group>
            </group>
        </group>
    )
}

useGLTF.preload('/house.glb')

const getNum = (name) => {
    return +name.split('floor').pop();
}

function getCenterPoint(mesh) {
    var geometry = mesh.geometry;
    geometry.computeBoundingBox();
    var center = new THREE.Vector3();
    geometry.boundingBox.getCenter(center);
    mesh.localToWorld(center);
    return center;
}