import { Table, Layout, Row, Col, Select, Slider } from "antd";
import React, { useState } from "react";
import { DropDownBlock } from "./DropDownBlock";
import { UpIcon } from "./icons/UpIcon";
import {useAppDispatch, useAppSelector} from "../store/store.utils";
import {applyFilters} from "../helpers/unitFilters";
import {readableDate} from "../helpers/readableDate";
import {$api_actions} from "../reducers/apiSlice";

export const ListView = ({ setRoomId, listViewSearch }) => {
  const { Sider, Header, Content } = Layout;
  const { Column, ColumnGroup } = Table;
  const dispatch = useAppDispatch();
  const units = useAppSelector(state => state.api.units)
  const priceFilter = useAppSelector(state => state.filter.price)
  const availabilityFilter = useAppSelector(state => state.filter.availability)
  const typeFilter = useAppSelector(state => state.filter.type)
  const bathroomsFilter = useAppSelector(state => state.filter.bathrooms)
  const dataSource = applyFilters(units, typeFilter, bathroomsFilter, availabilityFilter, priceFilter).filter(unit => unit.isAvailable);


  const columns = [
    {
      title: "Unit Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Apartment Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Total Area",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Availability Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <div className="ListView">
      {/*<Header style={{ width: "100%", height: "11%" }} />*/}
      <Layout
        style={{
          height: "100%",
          width: "100%",
          flexDirection: "row",
          display: "flex",
          backgroundColor: "#FBF7EE",
          margin: 0,
          padding: 0,
        }}
      >
        {/* <Sider
          // style={{ background: "#FBF7EE" }}
          className="ListView_slider"
        > */}
        <div
          className="ListView_slider"
          style={{
            display:
              listViewSearch || window.innerWidth > 425 ? "block" : "none",
          }}
        >
          <div className="ListView_filter">
            <div className="ListView_filter-caption">Filter Search</div>
            <DropDownBlock />
          </div>
        </div>

        {/* </Sider> */}
        <Content
          style={{
            background: "#FDFBF6",
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          <div className="ListView_table-header">
            <Col>
              <p>
                Found <strong>30 floorplans</strong> matching your criteria.
              </p>
            </Col>
            <div className="ListView_table-sort">
              <Col>Sort By</Col>
              <Col>
                <Select
                  defaultValue="Select"
                  suffixIcon={
                    <UpIcon
                      style={{
                        rotate: "180deg",
                        marginRight: "10px",
                        scale: "0.65",
                      }}
                    />
                  }
                  options={[
                    {
                      value: "select",
                      label: "Select",
                    },
                  ]}
                />
              </Col>
            </div>
          </div>
          <div className="ListView_table-container-up">
            {dataSource.map((item) => {
              let area = parseFloat(item.marketRent) / item.floorplan.maxRent * item.floorplan.maxSquareFeet
              let formattedDate = readableDate(item.madeReadyDate)
              if (item.floorplan.maxSquareFeet === item.floorplan.minSquareFeet) area = item.floorplan.maxSquareFeet

              return (
                <>
                  <button
                    onClick={() => dispatch($api_actions.setState({activeUnit: item}))} //change it later
                    className="ListView_table-container"
                  >
                    <div>
                      <p className="ListView_table-container-name">
                        {item.id}
                      </p>
                      <div className="ListView_table-container-inner">
                        <strong className="ListView_table-container-inner-type">
                            {`${item.floorplan.bedCount} Bedroom | ${item.floorplan.bathCount} Bathroom`}
                        </strong>
                        <hr />
                        <strong className="ListView_table-container-inner-area">
                            {area} SQ FT
                        </strong>
                        <hr />
                        <strong className="ListView_table-container-inner-price">
                          {item.marketRent}
                        </strong>
                        <hr />
                        <strong className="ListView_table-date">
                            {item.madeReadyDate === null ? 'Available' : formattedDate}
                        </strong>
                      </div>
                    </div>
                    <div className="ListView_table-container-icon">
                      <UpIcon />
                    </div>
                  </button>
                </>
              );
            })}
          </div>
          <Table
            dataSource={dataSource}
            pagination={false}
            scroll={{y: window.innerHeight - 250}}
            rowClassName="ListView_row"
            className="ListView_table"
            tableLayout='auto'
            style={{
              width: "100%",
              height: 'calc(100% - 88px)',
              padding: 0,
              margin: 0,
            }}
          >
            <Column
              title="Unit Name"
              dataIndex="id"
              key="name"
              render={(value) => (<p className='ListView_row-name'>{value}</p>)}
            />

              <Column
                title="Apartment Type"
                dataIndex="floorplan"
                key="type"
                render={(value) => <strong>{`${value.bedCount} Bedroom | ${value.bathCount} Bathroom`}</strong>}
              />
              <Column
                title="Total Area"
                dataIndex="area"
                key="area"
                rowSpan={null}
                render={(value, record) => {
                  let area = parseFloat(record.marketRent) / record.floorplan.maxRent * record.floorplan.maxSquareFeet
                  if (record.floorplan.maxSquareFeet === record.floorplan.minSquareFeet) area = record.floorplan.maxSquareFeet
                  return (
                      <strong>
                        {area} SQ FT
                      </strong>
                  )
                }}
              />
              <Column
                title="Price"
                dataIndex="marketRent"
                key="price"
                render={(value) => <strong>{value}</strong>}
              />
              <Column
                title="Availability Date"
                dataIndex="madeReadyDate"
                key="date"
                render={(value) => {
                    let formattedDate = readableDate(value)
                    return (
                        <strong>{value === null ? 'Available' : formattedDate}</strong>
                    )}}
              />
              <Column
                className="ListView_row-buttonColumn"
                title=""
                width={0}
                dataIndex="id"
                key="link"
                render={(value, record) => (
                  <button
                    className="ListView_row-button"
                    onClick={() => {
                        dispatch($api_actions.setState({activeUnit: record}))
                    }}
                  >
                    View Floorplan
                  </button>
                )}
              />
              <UpIcon className="ListView_row-arrow" />

          </Table>
        </Content>
      </Layout>
    </div>
  );
};
