import React from 'react';
import { Pannellum } from "pannellum-react";
import pano from './pano.jpeg'
export const PanoViewer = ({ setSelectedPano }) => (
  <>
    <button className='PanoViewer_close' onClick={() => setSelectedPano(null)}>
      &#10005;
    </button>
    <Pannellum
      width="100%"
      height="100%"
      image={pano}
      pitch={10}
      yaw={180}
      hfov={110}
      autoLoad
      showZoomCtrl={false}
      onLoad={() => {
        console.log("panorama loaded");
      }}
    />
  </>
)
