import {serviceOptions} from "./api.properties";
import {
    REACT_APP_API_CLIENT_ID,
    REACT_APP_API_CLIENT_SECRET,
    REACT_APP_API_TOKEN_ENDPOINT,
    REACT_APP_BASE_URL
} from "../constans/constans";
import axios from "axios";

const getToken = async () => {
    try {
        // @ts-ignore
        const response = await axios.post(REACT_APP_API_TOKEN_ENDPOINT, null, {
            params: {
                grant_type: 'client_credentials',
                client_id: REACT_APP_API_CLIENT_ID,
                client_secret: REACT_APP_API_CLIENT_SECRET,
            },
        });

        const accessToken = response.data.access_token;
        return accessToken;
    } catch (error) {
        console.error('Ошибка при получении Bearer токена:', error);
    }
};

getToken()
    .then((token) => {
        const instance = axios.create({
            baseURL: REACT_APP_BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        serviceOptions.axios = instance
    })

const instance = axios.create({
    baseURL: REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

serviceOptions.axios = instance