import { ScenegraphLayer } from "@deck.gl/mesh-layers";

const d = [
  {
    name: "Museum of Discovery <br/> and Science",
    code: "LF",
    address: "401 SW 2nd St, Fort Lauderdale, FL 33312",
    image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/18/7c/cf/0d/the-museum-of-discovery.jpg?w=1200&h=-1&s=1",
    entries: "1",
    exits: "1",
    coordinates: [26.12083535991619, -80.14786973592481],
  },
  {
    name: "The Wharf",
    code: "LF",
    address: "20 W Las Olas Blvd",
    image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1a/72/1e/13/welcome-to-the-wharf.jpg?w=1200&h=-1&s=1",
    entries: "1",
    exits: "1",
    coordinates: [26.1194731655019, -80.14382809867234],
  },
  
  {
    name: "Brightline Station",
    image: "https://www.gannett-cdn.com/presto/2021/08/10/NPPP/e9cc3bcf-763e-41ba-a8e9-e6e2477a8fa4-052118_Brightline_Miami_31.JPG?crop=2099,1181,x0,y106&width=2099&height=1181&format=pjpg&auto=webp",
    code: "LF",
    address: "101 NW 2nd Ave, Fort Lauderdale, FL 33311",
    entries: "1",
    exits: "1",
    coordinates: [26.123707891311394, -80.14628189684349],
  },
  {
    name: "American Social",
    code: "LF",
    address: "721 E Las Olas Blvd, Fort Lauderdale, FL 33301",
    entries: "1",
    image: "https://americansocialbar.com/wp-content/uploads/2021/02/americansocial-tampareservations1.jpg",
    exits: "1",
    coordinates: [26.119770756492883, -80.13549959058312],
  },
  {
    name: "The Fresh Market",
    code: "LF",
    address: "424 N Federal Hwy, Fort Lauderdale, FL 33301",
    entries: "1",
    exits: "1",
    image: "https://images.ctfassets.net/lufu0clouua1/25Tut5VxwKnf30XOUFLBfm/7a1dbf42ad701a4c95826d5fb7ae9a29/The-Fresh-Market-store-_231_-Columbia-SC-307-flat-_1_.jpg",
    coordinates: [26.12744595207354, -80.13661145260411],
  },
  {
    name: "Legacy Fit",
    code: "LF",
    address: "500 S Andrews Ave Unit 120, Fort Lauderdale, FL 33301",
    entries: "1",
    image: "https://legacyfit.com/wp-content/uploads/2021/12/Legacy-Studio-in-Wynwoodfff-scaled.jpg",
    exits: "1",
    coordinates: [26.11617466690411, -80.14307266765029],
  },
  {
    name: "FLOW BUILDING",
    code: "LF",
    address: "300 SW 1st Ave",
    entries: "1",
    exits: "1",
    coordinates: [26.11944269790867, -80.144855417212],
    color: [255, 0, 0, 255],
    main: true,
    translation: [0, 0, 275],
  },
];

const usePins = (onClick) => {
  const layer = new ScenegraphLayer({
    id: "scenegraph-layer",
    onClick,
    data: d,
    pickable: true,
    scenegraph: "./pin4.glb",
    getPosition: (d) => [d.coordinates[1], d.coordinates[0]],
    getOrientation: (d) => [0, 0, 90],
    getTranslation: (d) => d.translation || [0, 0, 30],
    sizeScale: 5,
    _lighting: "flat",
    getColor: (d) => d.color || [150, 150, 150, 255],
  });

  return layer;
};

export { usePins };
