import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { PlanViewer } from "./components/PlanViewer/PlanViewer";
import { Button, Spin } from "antd";
import { FloorHover } from "./components/FloorHover";
import { Footer } from "./components/Footer";
import { AnimatePresence, motion } from "framer-motion";
import { PanoViewer } from "./components/PanoViewer";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import { OrbitControls } from "@react-three/drei";
import { HouseModel } from "./HouseModel";
import * as TWEEN from "@tweenjs/tween.js";
import { Header } from "./components/Header";
import { ListView } from "./components/ListView";
import { UpIcon } from "./components/icons/UpIcon";
import InfoCard from "./components/InfoCard";
import UnitImg from "./components/icons/unit-3.png";
import { useAppDispatch, useAppSelector } from "./store/store.utils";
import { $api_actions } from "./reducers/apiSlice";
import { GENERIC_PROPERTY_ID } from "./constans/constans";
import { FLOORLANS_IMAGES_TOURS } from "./constans/floorplans";

export const House = () => {
  const [plan, setPlan] = React.useState(null);
  const [hovered, setHovered] = React.useState(null);
  const [amentiesView, setAmenitiesView] = React.useState(false);
  const [selectedAmenity, setSelectedAmenity] = React.useState(null);
  const [selectedPano, setSelectedPano] = React.useState(null);
  const [footerHovered, setFooterHovered] = React.useState(null);
  const [roomId, setRoomId] = React.useState(null);
  const [listView, setListView] = React.useState(false);
  const [listViewSearch, setListViewSearch] = useState(false);
  const [availableFloors, setAvailableFloors] = React.useState([]);
  const [vtOpenedStyle, setVTOpened] = useState(null);
  const navigate = useNavigate();

  const planViewRef = React.useRef(0);
  const lightRef = React.useRef(0);
  const controlsRef = React.useRef(null);

  const [filterOptions, setFilterOptions] = React.useState({
    priceRange: "1000-2000",
    bedRooms: "2bed",
    bathRooms: "3bath",
    amenities: "amenities",
    date: "now",
  });

  window.addEventListener("message", (e) => {
    if (e.data?.message === "close") {
      setRoomId(null);
      dispatch($api_actions.setState({ activeUnit: null }));
    }
  });

  // const Effect = () => (
  //   <EffectComposer>
  //   </EffectComposer>
  // );

  const onBack = () => {
    navigate("/");
  };

  React.useEffect(() => {
    if (!amentiesView) {
      setSelectedAmenity(null);
    }
  }, [amentiesView]);

  React.useEffect(() => {
    console.log(lightRef);
  }, [lightRef]);

  // window.addEventListener('click', () => {
  //   console.log(controlsRef.current.object.position, controlsRef.current.target)
  // })

  const canvasRef = React.useRef(0);

  const rotateModel = (angle) => {
    const controls = controlsRef.current;
    const rotateDirection = angle ? "-" : "+";
    new TWEEN.Tween({ angle: controls.getAzimuthalAngle() })
      .to({ angle: rotateDirection + Math.PI / 2 }, 1000)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onUpdate((props) => {
        controls.setAzimuthalAngle(props.angle);
      })
      .start();
  };

  const Light = () => {
    useFrame(() => {
      TWEEN.update();
    });

    return <ambientLight />;
  };
  const dispatch = useAppDispatch();
  const floorplans = useAppSelector((state) => state.api.floorplans);
  const activeUnit = useAppSelector((state) => state.api.activeUnit);
  const isLoading = useAppSelector((state) => state.api.isLoading);

  const handleHoverChange = (isUp = false) => {
    if (isUp) {
      if (hovered === 34) return;
      setHovered((prev) => prev + 1);
    } else {
      if (hovered === null) return setHovered(0);
      if (hovered === 0) return;
      setHovered((prev) => prev - 1);
    }
  };

  const closeUnit = () => {
    dispatch($api_actions.setState({ activeUnit: null }));
  };

  const imagesAndTours =
    activeUnit && FLOORLANS_IMAGES_TOURS[activeUnit.floorplan.id];

  const handleVT = () => {
    setVTOpened('Unfurnished');
  };

  return (
    <div className="background">
      <Canvas
        ref={canvasRef}
        concurrent
        className="threeCanvas"
        camera={{
          near: 5,
          far: 100000,
          zoom: window?.innerWidth > 425 ? 1 : 0.5,
          fov: 50,
          position: [-38, 55, 66],
        }}
      >
        {/* <Effect /> */}
        <Light ref={lightRef} />
        <OrbitControls
          target={[89, 46, -45]}
          ref={controlsRef}
          maxPolarAngle={1.6}
          maxDistance={515}
          minDistance={100}
        />
        <HouseModel
          setPlan={setPlan}
          setHovered={setHovered}
          amenitiesView={amentiesView}
          selectedAmenity={selectedAmenity}
          setSelectedAmenity={setSelectedAmenity}
          setSelectedPano={setSelectedPano}
          footerHovered={footerHovered}
          availableFloors={availableFloors}
          setAvailableFloors={setAvailableFloors}
        />
      </Canvas>
      {isLoading && <Spin className="houseLoading" size="large" />}

      <div className="Header_selector">
        <button
          onClick={() => handleHoverChange(true)}
          className="Header_selector-btn"
        >
          <UpIcon />
        </button>
        <div className="Header_selector-numberContainer">
          {hovered !== null ? `Floor ${hovered}` : ""}
        </div>
        <button
          onClick={() => handleHoverChange(false)}
          className="Header_selector-btn-rotate"
        >
          <UpIcon />
        </button>
      </div>


      <AnimatePresence>
        {selectedPano !== null && (
          <motion.div
            className="PanoViewer"
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.1 }}
            exit={{ opacity: 0, scale: 0.3 }}
          >
            <PanoViewer setSelectedPano={setSelectedPano} />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {plan !== null && !listView && activeUnit === null ? (
          <motion.div
            className="PlanViewer"
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, scale: 0.6 }}
          >
            <PlanViewer
              plan={plan}
              setPlan={setPlan}
              planViewRef={planViewRef}
              setRoomId={setRoomId}
              roomId={roomId}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {listView &&
          roomId === null &&
          plan === null &&
          activeUnit === null && (
            <motion.div
              className="PlanViewer"
              initial={{ opacity: 0, scale: 0.6 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0, scale: 0.6 }}
            >
              <ListView listViewSearch={listViewSearch} setRoomId={setRoomId} />
            </motion.div>
          )}
      </AnimatePresence>

      {hovered !== null ? <FloorHover hovered={hovered} /> : ""}
      {activeUnit === null && (
        <Footer
          planViewRef={planViewRef}
          setPlan={setPlan}
          plan={plan}
          setFooterHovered={setFooterHovered}
          rotateModel={rotateModel}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          amentiesView={amentiesView}
          setAmenitiesView={setAmenitiesView}
          setListView={setListView}
          listView={listView}
          listViewSearch={listViewSearch}
          onListViewSearch={(val) => setListViewSearch(val)}
          availableFloors={availableFloors}
        />
      )}

      <AnimatePresence>
        {activeUnit !== null && (
          <motion.div
            className="tourIframe"
            initial={{ opacity: 0, translateX: 1000 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, translateX: 1000 }}
            // style={{
            //   display: "block",
            //   flexDirection: "column",
            // }}
          >
            <div className="Tour">
              {vtOpenedStyle ? (
                <>
                  <div className="vt-iframe">
                    <iframe key={vtOpenedStyle} src={imagesAndTours.tourLink[vtOpenedStyle]} />

                    <div className="vt-iframe-panel">
                      <div onClick={() => setVTOpened(null)} className="vt-iframe-panel-back vt-iframe-panel-button">
                        Exit
                      </div>

                      <div className="vt-iframe-panel-styles">
                        <div onClick={() => setVTOpened('Unfurnished')} className={`vt-iframe-panel-button ${vtOpenedStyle === 'Unfurnished' && 'vt-iframe-panel-button_active'}`}>
                          Unfurnished
                        </div>
                        <div onClick={() => setVTOpened('Scandinavian')} className={`vt-iframe-panel-button ${vtOpenedStyle === 'Scandinavian' && 'vt-iframe-panel-button_active'}`}>
                          Scandinavian
                        </div>
                        <div onClick={() => setVTOpened('Contemporary')} className={`vt-iframe-panel-button ${vtOpenedStyle === 'Contemporary' && 'vt-iframe-panel-button_active'}`}>
                          Contemporary
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="Tour-middleContainer">
                    <InfoCard
                      inputData={activeUnit}
                      pdfLink={imagesAndTours?.pdfLink}
                    />
                    <div
                      className="Tour-middleContainer-img-wrapper"
                      style={{ backgroundImage: `url(${imagesAndTours?.img})` }}
                    ></div>
                  </div>
                  <div className="Tour-btnContainer">
                    <button
                      onClick={closeUnit}
                      className="Tour-btnContainer-backArrow"
                    >
                      <UpIcon />
                    </button>
                    <button
                      onClick={handleVT}
                      className="Tour-btnContainer-tour"
                    >
                      Virtual Tour
                    </button>
                    <div style={{ width: 40, height: 40 }} />
                  </div>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {roomId === null && <Header />}
    </div>
  );
};
