import * as React from "react"

export const Logo = (props) => (
  <svg
    width={50}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 50C11.193 50 0 38.807 0 25S11.193 0 25 0s25 11.193 25 25-11.193 25-25 25Zm0-1.791c12.818 0 23.209-10.392 23.209-23.209 0-.602-.023-1.2-.068-1.79H42.48c-.99 0-1.935.801-2.11 1.79l-.556 3.151c-.314 1.723-1.962 3.115-3.688 3.115-.879 0-1.609-.36-2.075-.941-.672.58-1.53.941-2.407.941-1.728 0-2.883-1.392-2.588-3.115L29.611 25c.175-.989-.486-1.79-1.477-1.79-.651 0-1.283.346-1.689.865.805.738 1.204 1.88.977 3.163-.392 2.224-2.517 4.028-4.746 4.028-1.201 0-2.186-.523-2.778-1.355-.803.826-1.912 1.355-3.05 1.355-1.98 0-3.303-1.603-2.953-3.581l.949-5.371c.175-.989-.486-1.79-1.478-1.79-.991 0-1.935.801-2.11 1.79l-.157.895h.896c.495 0 .826.401.739.895-.088.495-.56.895-1.055.895h-.896l-.476 2.685C9.96 29.66 8.07 31.265 6.09 31.265h-3.44C5.384 41.039 14.354 48.208 25 48.208ZM23.78 25c-1.238 0-2.419 1.003-2.638 2.238-.218 1.236.61 2.237 1.847 2.237s2.418-1.003 2.637-2.238C25.845 26.002 25.017 25 23.78 25ZM25 1.791C12.182 1.791 1.791 12.182 1.791 25c0 1.531.15 3.027.433 4.476H6.4c.99 0 1.935-.801 2.11-1.79l.475-2.685H8.09c-.495 0-.826-.401-.739-.895.088-.495.56-.895 1.055-.895h.896l.16-.895c.348-1.977 2.238-3.581 4.218-3.581s3.304 1.602 2.954 3.581l-.949 5.372c-.175.988.486 1.79 1.478 1.79.99 0 1.934-.802 2.11-1.79h.024c.01-.148.028-.297.054-.448.393-2.225 2.518-4.029 4.747-4.029.3 0 .587.033.858.095.804-1.122 2.127-1.886 3.493-1.886 1.981 0 3.303 1.604 2.953 3.581l-.556 3.151c-.12.734.374 1.325 1.11 1.325.737 0 1.439-.59 1.579-1.325l.715-4.046c.087-.495.56-.895 1.055-.895.495 0 .826.402.739.895l-.715 4.046c-.12.734.373 1.325 1.11 1.325.736 0 1.44-.59 1.578-1.325l.557-3.15c.349-1.978 2.239-3.582 4.218-3.582h5.14C46.212 10.301 36.6 1.791 25 1.791Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={25}
        y1={0}
        x2={25}
        y2={50}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.2} stopColor="#8FADAE" />
        <stop offset={0.45} stopColor="#D899AF" />
        <stop offset={0.75} stopColor="#F0B043" />
        <stop offset={1} stopColor="#AD6A41" />
      </linearGradient>
    </defs>
  </svg>
)
