export function getOptions(data) {
  const bedCountSet = new Set();
  const bathroomsCountSet = new Set();
  const rentValues = []

  const sortedUnitsData = sortUnitsData(data)

  sortedUnitsData.forEach((unitData) => {
    unitData.units.forEach((unit) => {
      if (unit.isAvailable && unit.floorplan) {
        if (unit.floorplan.bedCount !== undefined) {
          bedCountSet.add(unit.floorplan.bedCount);
        }
        if (unit.floorplan.bathCount !== undefined) {
          bathroomsCountSet.add(unit.floorplan.bathCount);
        }
        if (unit.marketRent !== undefined) {
          rentValues.push(parseFloat(unit.marketRent));
        }
      }
    });
  });

  const filteredBedroomOptions = bedroomOptions.filter((option) =>
    bedCountSet.has(parseInt(option.value))
  );

  const filteredBathroomsOptions = bathroomsOptions.filter((option) =>
    bathroomsCountSet.has(parseInt(option.value))
  );
  const minRent = Math.min(...rentValues);
  const maxRent = Math.max(...rentValues);
  const filteredPriceOptions = [minRent, maxRent];

  return { filteredBedroomOptions, filteredBathroomsOptions, filteredPriceOptions };
}

export function sortUnitsData(unitsData) {
  const sortedKeys = Object.keys(unitsData)
    .map(Number)
    .filter((key) => unitsData[key]?.units && unitsData[key].units.length > 0)
    .sort((a, b) => a - b);

  const sortedUnitsData = sortedKeys.map((key) => unitsData[key]);
  return sortedUnitsData;
}


const bedroomOptions = [
  {
    value: '0',
    label: 'Studio',
  },
  {
    value: '1',
    label: '1 Bedroom',
  },
  {
    value: '2',
    label: '2 Bedroom',
  },
  {
    value: '3',
    label: '3 Bedroom',
  },
];

const bathroomsOptions = [
  {
    value: '1',
    label: '1 Bathroom',
  },
  {
    value: '2',
    label: '2 Bathrooms',
  },
  {
    value: '3',
    label: '3 Bathrooms',
  },
];
