import React from 'react'
import { Row, Col } from "antd";

export const FloorHover = ({ hovered }) => (
  <div className="floorHover">
    <Row style={{height: '80%'}}>
      <Col offset={8} span={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className="floorHover_number">
          {hovered}
        </div>
      </Col>
      {/* <Col offset={2} span={5} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className="floorHover_info">
          <div className="floorHover_img">
            <img src="./floorPlan.png" alt="floorPlan" style={{maxWidth:'100%', maxHeight: '100%'}}/>
          </div>
          <div className="floorHover_caption">
            Floor {hovered}
          </div>
          <div className="floorHover_available">
            24 apartments available
          </div>
        </div>
      </Col> */}
    </Row>
  </div>
)
