import React from "react";
import { Row, Col, Select, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { UpIcon } from "./icons/UpIcon";
import { RotateIcon } from "./icons/RotateIcon";
import { DropDownBlock } from "./DropDownBlock";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./Footer.module.css";

export const Footer = ({
  setPlan,
  plan,
  rotateModel,
  filterOptions,
  setFilterOptions,
  planViewRef,
  setAmenitiesView,
  amentiesView,
  setFooterHovered,
  setListView,
  listView,
  onListViewSearch,
  listViewSearch,
  availableFloors
}) => {
  const [searchOpen, setSearchOpen] = React.useState(true);

  const handleChange = (prop, value) => {
    setFilterOptions((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };

  const rotateImg = (angle) => {
    const { rotate } = planViewRef.current.style;
    const currentAngle = rotate ? parseInt(rotate) : 0;
    planViewRef.current.style.rotate = currentAngle + angle + "deg";
  };

  const handleOpen = () => {
    if (listView) onListViewSearch(!listViewSearch);
    else setSearchOpen((prev) => !prev);
  };

  return (
    <div className="footer">
      <Row
      // style={{ width: "100%", paddingInline: 20 }}
      >
        <Col span={4}>
          <div className="footer_wrapper">
            {plan !== null && (
              <button
                className={`${styles["footer_button"]} ${styles["footer_button-standart"]} ${styles["back-arrow"]}`}
                onClick={() => setPlan(null)}
              >
                <UpIcon style={{ rotate: "-90deg" }} />
                BACK
              </button>
            )}
          </div>
        </Col>
        <Col span={16}>
          <div className="footer_wrapper footer_wrapper-center">
            {plan === null &&
              ((listView && window.innerWidth <= 425) || !listView) && (
                <button
                  className={`${styles["footer_button"]} 
                ${styles["footer_button-standart"]} ${
                    searchOpen ? styles["footer_button-active"] : ""
                  }`}
                  // style={{ fontSize: "25px" }}
                  onClick={handleOpen}
                >
                  <SearchOutlined className={styles["search-btn"]} />
                </button>
              )}
            <button
                disabled={availableFloors.length === 0}
              className={`${styles["footer_button"]} ${
                styles["footer_button-center"]
              } ${plan !== null ? styles["footer_button-active"] : ""}` }
              onClick={() => {
                setListView(false);
                onListViewSearch(false);
                setSearchOpen(true)
                setPlan(null)
              }}
            >
              3D View
            </button>
            <button
              className={`${styles["footer_button"]} ${
                styles["footer_button-center"]
              } ${listView && styles["footer_button-active"]} ${
                styles["listview"]
              }`}
              // className={`footer_button footer_button-center ${listView && 'footer_button-active'}`}
              onClick={() => {
                setListView((prev) => {
                  if (prev) {
                    onListViewSearch(false);
                    setSearchOpen(false);
                  }
                  return true
                });

                setPlan(null);
              }}
            >
              List View
            </button>
            <AnimatePresence>
              {searchOpen && plan === null && !listView && (
                <motion.div
                  className="footer_search"
                  initial={{ opacity: 0, scale: 0.6 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                  exit={{ opacity: 0, scale: 0.3 }}
                >
                  <DropDownBlock setSearchOpen={setSearchOpen}/>
                  
                </motion.div>
              )}
            </AnimatePresence>
            {plan === null && !listView && (
              <>
                <div className="footer_wrapper-rotate">
                  <button
                    className={`${styles["footer_button"]} ${styles["footer_button-standart"]} footer_button-rotate`}
                    onClick={() => rotateModel(true)}
                    style={{ margin: 0 }}
                  >
                    <RotateIcon
                      className={styles["rotate-image"]}
                      //  style={{ transform: "scaleX(-1)" }}
                    />
                  </button>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col span={1}></Col>
      </Row>
    </div>
  );
};
