import React from 'react';
import {$api_actions} from "./reducers/apiSlice";
import {GENERIC_PROPERTY_ID} from "./constans/constans";
import {useAppDispatch, useAppSelector} from "./store/store.utils";

const ProjectLoader = () => {

    const dispatch = useAppDispatch()
    const floorplans = useAppSelector(state => state.api.floorplans)

    React.useEffect(() => {
        setTimeout(() => {
            dispatch($api_actions.loadFloorplans(GENERIC_PROPERTY_ID))
            // dispatch($api_actions.loadUnits(GENERIC_PROPERTY_ID))
        }, 1000)
    }, [])

    React.useEffect(() => {
        if (floorplans.length > 0) {
            dispatch($api_actions.setState({units: []}))
            dispatch($api_actions.loadUnits({id: GENERIC_PROPERTY_ID, floorplans: floorplans}))
        }
    }, [floorplans])

    return null;
};

export default ProjectLoader;